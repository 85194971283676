export enum ProjectActionsTypes {
  SET_PROJECT_DATA = "SET_PROJECT_DATA",
  SET_SUBMISSION_DATA = "SET_SUBMISSION_DATA",
  SET_ROUTE_NEXT = "SET_ROUTE_NEXT",
  SET_ROUTE_PREV = "SET_ROUTE_PREV",
  SET_INFORMATION = "SET_INFORMATION",
  SET_SUB_INFORMATION = "SET_SUB_INFORMATION",
  SET_PRODUCT_RESEARCH_LOADING = "SET_PRODUCT_RESEARCH_LOADING",
  SET_MARKET_RESEARCH_LOADING = "SET_MARKET_RESEARCH_LOADING",
  SET_PROGRESS = "SET_PROGRESS",
  SET_ENABLED_HISTORY_STATUS = "SET_ENABLED_HISTORY_STATUS",

  // Product Research
  SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION = "SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION",
  SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION_LOADING = "SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION_LOADING",
  SET_PRODUCT_RESEARCH_MAIN_FEATURES = "SET_PRODUCT_RESEARCH_MAIN_FEATURES",
  SET_PRODUCT_RESEARCH_MAIN_FEATURES_LOADING = "SET_PRODUCT_RESEARCH_MAIN_FEATURES_LOADING",
  SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS = "SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS",
  SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS_LOADING = "SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS_LOADING",

  // Market Research
  SET_MARKET_RESEARCH_TRENDS = "SET_MARKET_RESEARCH_TRENDS",
  SET_MARKET_RESEARCH_TRENDS_LOADING = "SET_MARKET_RESEARCH_TRENDS_LOADING",
  SET_MARKET_RESEARCH_DEMAND = "SET_MARKET_RESEARCH_DEMAND",
  SET_MARKET_RESEARCH_DEMAND_LOADING = "SET_MARKET_RESEARCH_DEMAND_LOADING",

  // Audience Archetype
  SET_AUDIENCE_ARCHETYPE_DATA = "SET_AUDIENCE_ARCHETYPE",
  SET_AUDIENCE_ARCHETYPE_LOADING = "SET_AUDIENCE_ARCHETYPE_LOADING",
  SET_SELECTED_AUDIENCE_ARCHETYPE = "SET_SELECTED_AUDIENCE_ARCHETYPE",

  // Challenge and Task
  SET_CHALLENGE_AND_TASK_DATA = "SET_CHALLENGE_AND_TASK_DATA",
  SET_CHALLENGE_AND_TASK_LOADING = "SET_CHALLENGE_AND_TASK_LOADING",

  // Target Audience
  SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS = "SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS",
  SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS_LOADING = "SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS_LOADING",
  SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS = "SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS",
  SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS_LOADING = "SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS_LOADING",
  SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS = "SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS",
  SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS_LOADING = "SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS_LOADING",
  SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS = "SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS",
  SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS_LOADING = "SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS_LOADING",

  // One-Page Strategy
  SET_ONE_PAGE_STRATEGY_DATA = "SET_ONE_PAGE_STRATEGY_DATA",
  SET_ONE_PAGE_STRATEGY_LOADING = "SET_ONE_PAGE_STRATEGY_LOADING",

  // Key Insight
  SET_KEY_INSIGHT_DATA = "SET_KEY_INSIGHT",
  SET_KEY_INSIGHT_LOADING = "SET_KEY_INSIGHT_LOADING",

  // Competitor Analysis
  SET_COMPETITOR_ANALYSIS_DATA = "SET_COMPETITOR_ANALYSIS_DATA",
  SET_COMPETITOR_ANALYSIS_LOADING = "SET_COMPETITOR_ANALYSIS_LOADING",

  // Opportunity Among Competition
  SET_OPPORTUNITY_AMONG_COMPETITION_DATA = "SET_OPPORTUNITY_AMONG_COMPETITION_DATA",
  SET_OPPORTUNITY_AMONG_COMPETITION_LOADING = "SET_OPPORTUNITY_AMONG_COMPETITION_LOADING",
  SET_OPPORTUNITY_AMONG_COMPETITION_BENEFIT = "SET_OPPORTUNITY_AMONG_COMPETITION_BENEFIT",

  // Key Touch Point
  SET_KEY_TOUCH_POINT_DATA = "SET_KEY_TOUCH_POINT_DATA",
  SET_KEY_TOUCH_POINT_LOADING = "SET_KEY_TOUCH_POINT_LOADING",

  // Product Research
  SET_IDEA_ALIGNMENT_DATA = "SET_IDEA_ALIGNMENT_DATA",
  SET_IDEA_ALIGNMENT_LOADING = "SET_IDEA_ALIGNMENT_LOADING",
}
