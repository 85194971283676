import axios from "axios";
import Cookies from "js-cookie";

// Create an instance of axios
const api = axios.create({
  baseURL: "/api/",
  withCredentials: true,
});

// Add a request interceptor to include the Bearer token and default headers
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("access_token");
    config.headers["Content-Type"] = "application/json";

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor for error handling
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove("access_token");
      if (window.location.pathname !== "/login")
        window.location.href = "/login";
    }

    if (error.response && error.response.status === 404) {
      // window.location.href = "/404";
    }

    return Promise.reject(error);
  },
);

export const fetcher = {
  get: (url: string) => api.get(url),
  post: (url: string, data: any) => api.post(url, data),
  put: (url: string, data: any) => api.put(url, data),
  delete: (url: string) => api.delete(url),
  patch: (url: string, data: any) => api.patch(url, data),
};

export const defaultQueryFn = async ({ queryKey }: any) => {
  try {
    const { data } = await api.get(queryKey[0], { params: queryKey[1] });
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) throw err.response;
    throw err;
  }
};

export default api;
