import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { Schema } from "@/Types/creative-idea-alignment/schema";

const submit = async ({
  payload,
  projectSlug,
  id,
}: {
  payload: Schema;
  projectSlug: string;
  id: number;
}) => {
  try {
    const { data } = await fetcher.post(
      `/${projectSlug}/idea-alignment/store/${id}`,
      {
        content: payload,
      },
    );

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useSubmitIdeaAlignment = () => {
  return useMutation<
    any,
    Error,
    { payload: Schema; projectSlug: string; id: number }
  >({
    mutationFn: submit,
  });
};

export { useSubmitIdeaAlignment };
