import { z } from "zod";

export const IdeaPersonalizationSchema = z.object({
  idea_title: z.string().min(4, "Idea Title is required"),
  idea_description: z.string().min(4, "Idea Description is required"),
  reason_product_brand: z.string().min(4, "Reason Product Brand is required"),
  reason_target_audience: z
    .string()
    .min(4, "Reason Target Audience is required"),
});

export type Schema = z.infer<typeof IdeaPersonalizationSchema>;
