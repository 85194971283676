import React, { forwardRef } from "react";
import TextArea from "./TextArea";

const CardInput = forwardRef(
  (
    {
      isFetching = false,
      isEditing,
      title,
      value,
      defaultValue,
      error,
      onChange,
      placeholder,
      viewPlaceholder = placeholder,
      showTitle = true,
      rows = 2,
    }: {
      isFetching?: boolean;
      isEditing: boolean;
      title: string;
      value: string;
      defaultValue: string;
      error?: string;
      onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
      placeholder?: string;
      viewPlaceholder?: string;
      showTitle?: boolean;
      ref?: React.ForwardedRef<HTMLTextAreaElement>;
      rows?: number;
    },
    ref: React.Ref<HTMLTextAreaElement>,
  ) => (
    <div className='flex flex-col gap-4'>
      {showTitle && (
        <h3 className='font-semibold text-12 leading-14'>{title}</h3>
      )}
      {isFetching ? (
        <div className='text-14 py-4 min-h-50'>
          <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full mb-14' />
        </div>
      ) : isEditing ? (
        <TextArea
          name={title.toLowerCase()}
          value={value}
          error={error}
          containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
          className='mt-1 block w-full !text-14 resize-none overflow-custom'
          rows={rows}
          placeholder={placeholder}
          isFocused={false}
          onChange={onChange}
          ref={ref}
        />
      ) : !defaultValue ? (
        <p className='text-14 py-4 min-h-50 text-placeholder-redx'>
          {viewPlaceholder}
        </p>
      ) : (
        <p className='text-14 py-4 min-h-50'>{defaultValue}</p>
      )}
    </div>
  ),
);

export default CardInput;
