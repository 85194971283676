import React, { Fragment, useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import InputLabel from "@/Components/InputLabel";

const SectionInformation: React.FC<{
  title: string;
  description: string;
}> = ({ title, description }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className='relative'>
      <PopoverButton
        ref={buttonRef}
        className='flex items-center gap-x-4'>
        <Icon
          className='size-20 text-blue-redx'
          icon='lucide:badge-info'
        />
      </PopoverButton>
      <Transition
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'>
        <PopoverPanel
          style={{
            position: "absolute",
            top: "25px",
            left: "0px",
          }}
          className='md:w-full lg:min-w-525 !max-w-525 z-30 divide-y rounded-lg bg-white shadow-lg p-15'>
          {({ close }) => (
            <Fragment>
              <InputLabel
                htmlFor='title'
                value={title}
                className='!text-black-redx text-14 font-semibold mb-8'
              />
              <p className='block w-full !text-12 text-grey-redx mb-15 leading-relaxed font-normal'>
                {description}
              </p>
              <div className='flex w-full justify-end gap-x-15'>
                <button
                  className='text-15 px-14 py-10 text-white bg-blue-redx rounded-8 font-semibold leading-18'
                  onClick={() => {
                    close();
                  }}>
                  Understand
                </button>
              </div>
            </Fragment>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SectionInformation;
