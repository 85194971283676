import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  IdeaAlignmentSchema,
  Schema,
} from "@/Types/creative-idea-alignment/schema";
import CardInput from "@/Components/Projects/SliderCard/CardInput";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import {
  IdeaAlignmentContent,
  IdeaAlignmentKeys,
  IdeaAlignmentList,
} from "@/Types/creative-idea-alignment/index";
import { Icon } from "@iconify/react";
import { CardHandles } from "../Index";
import { ProjectProps } from "@/Types/projects";
import { useUpdateSelectedIdeaAlignment } from "@/Hooks/react-query/idea-alignment/useUpdateSelected";

const Card = forwardRef<
  CardHandles,
  {
    project: ProjectProps;
    totalSelected: number;
    id: number;
    totalIdeaAlignment: number;
    ideaAlignment: IdeaAlignmentContent;
    selectedIdea?: IdeaAlignmentList | null;
    index: number;
    activeIndex: number;
    isEditing: boolean;
    isEdited: boolean;
    isEditor: boolean;
    isFetching?: boolean;
    isSelected: boolean;
    onSubmitSuccess: (data: Schema, id: number, index: number) => void;
    onSelectSuccess: (isShowPopUp: boolean) => void;
  }
>(
  (
    {
      project,
      totalSelected,
      isFetching = false,
      id,
      totalIdeaAlignment,
      ideaAlignment,
      index,
      isEditing,
      isEdited,
      isSelected,
      isEditor,
      onSelectSuccess,
      onSubmitSuccess,
    },
    ref,
  ) => {
    const [isSelectedButton, setIsSelectedButton] = useState(true);
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedIdeaAlignment();

    const defaultValues: Schema = useMemo(
      () => ({
        idea_title: ideaAlignment?.idea_title ?? "",
        idea_description: ideaAlignment?.idea_description ?? "",
        reason: ideaAlignment?.reason ?? "",
      }),
      [ideaAlignment],
    );

    const onSubmit = (data: Schema) => {
      onSubmitSuccess(data, id, index);
    };

    const { handleSubmit, reset, control } = useForm<Schema>({
      resolver: zodResolver(IdeaAlignmentSchema),
      defaultValues,
      mode: "all",
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit((formData) => onSubmit(formData))(),
      }),
      [handleSubmit, onSubmit],
    );

    useEffect(() => {
      reset(defaultValues);
    }, [isEditing]);

    useEffect(() => {
      setIsSelectedButton(!isSelected && totalSelected >= 1);
    }, [totalSelected, isSelected]);

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: () => {
            onSelectSuccess(false);
          },
        },
      );
    };

    return (
      <>
        <form className='flex flex-col w-full transition duration-500'>
          {!isFetching && (
            <div className='py-15 px-35 text-center inline-flex gap-8 justify-center items-center'>
              <h1 className='text-1620 font-bold'>
                Idea Alignment {index + 1} of {totalIdeaAlignment}
              </h1>
              {isEdited && (
                <div className='bg-soft-grey-redx px-8 py-4 text-grey-redx text-12 leading-14 rounded-8'>
                  Edited
                </div>
              )}
            </div>
          )}

          {isFetching && (
            <h1 className='w-3/12 h-20 animate-pulse bg-soft-purple-redx rounded-full mx-auto mb-14' />
          )}
          <div
            className={`p-24 grid grid-cols-1 gap-24 transition duration-500 text-black-redx ${
              isEditing && isSelected ? "!bg-transparent" : ""
            } ${
              isSelected
                ? "border-t-2 border-blue-redx bg-softer-purple-redx"
                : "border-t-1 border-stroke-redx"
            }`}>
            {Object.keys(IdeaAlignmentSchema.shape).map((field, idx) => (
              <Controller
                control={control}
                name={field as keyof Schema}
                key={idx}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const title =
                    field.charAt(0).toUpperCase() +
                    field.slice(1).replace(/_/g, " ");

                  return (
                    <CardInput
                      isFetching={isFetching}
                      isEditing={isEditing}
                      value={value}
                      defaultValue={ideaAlignment?.[field as IdeaAlignmentKeys]}
                      error={error?.message}
                      placeholder={`Input the ${field.replace(/_/g, " ")} here`}
                      title={title}
                      onChange={onChange}
                    />
                  );
                }}
              />
            ))}
          </div>
          <div className='w-full inline-flex gap-7 p-10 items-center border-b-1 border-t-1 border-soft-purple-redx mb-20 px-30'>
            <div className='w-20 mx-20'>
              <Icon
                className='size-20 text-black-redx'
                icon='lucide:message-circle-question'
              />
            </div>
            <div className='w-auto '>
              <h1 className='font-semibold'>How Ainstein aligned this Idea?</h1>
              <p className='text-grey-redx'>
                Explanation of what Ainstein did to align the idea into
                strategy, including what changes are made. Lorem ipsum odor
                amet, consectetuer adipiscing elit. Non ullamcorper adipiscing
                viverra posuere scelerisque. Quam ac nullam vulputate ligula ex
                aliquet. Felis turpis quis vehicula tempus mus vel cubilia
                iaculis. Phasellus euismod libero diam aliquam risus arcu semper
                maximus phasellus.
              </p>
            </div>
          </div>
          <div className='w-full justify-center flex items-center mb-35'>
            {id !== -1 && isEditor && (
              <SelectButton
                index={index}
                isLoading={isUpdatingSelected}
                isEditing={isEditing}
                isSelected={isSelected}
                isDisabled={isSelectedButton}
                onSelect={() => handleSelected(id)}
              />
            )}
          </div>
        </form>
      </>
    );
  },
);

export default Card;
