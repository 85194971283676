import Wrapper from "@/Pages/CreativeIdeaAlignment/Wrapper";

const CreativeIdeaAlignmentRoutes = [
  {
    path: "/:project_slug/idea-alignment",
    element: <Wrapper />,
  },
];

export default CreativeIdeaAlignmentRoutes;
