import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  InputHTMLAttributes,
} from "react";

export default forwardRef(function TextInput(
  {
    type = "text",
    className = "",
    containerClassName = "",
    isFocused = false,
    placeholder = "",
    light = false,
    error = "",
    rightElement = null,
    ...props
  }: InputHTMLAttributes<HTMLInputElement> & {
    isFocused?: boolean;
    rightElement?: React.ReactNode;
    containerClassName?: string;
    error?: string;
    light?: boolean;
  },
  ref,
) {
  const localRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }));

  useEffect(() => {
    if (isFocused) {
      localRef.current?.focus();
    }
  }, [isFocused]);

  const placeholderClass = light
    ? "placeholder-lightplaceholder-redx"
    : "placeholder-placeholder-redx";

  return (
    <div
      className={
        `bg-transparent flex w-full items-center overflow-hidden transition-all border-b-1 duration-300 focus-within:!border-b-2  ${
          error ? "!border-red-500" : "border-soft-purplestroke-redx"
        }` + containerClassName
      }>
      <input
        {...props}
        type={type}
        className={
          `px-0 py-2 bg-transparent rounded-md shadow-sm text-12 !border-transparent !focus:border-transparent !focus:ring-0 ${placeholderClass} ` +
          className
        }
        placeholder={placeholder}
        ref={localRef}
      />
      {rightElement}
    </div>
  );
});
