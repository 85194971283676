import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveSubInformation,
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import GenerateLoading from "@/Components/GenerateLoading";
import { Icon } from "@iconify/react";
import SelectedCard from "@/Components/SelectedCard";
import SourcesList from "@/Components/Projects/SourcesList";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import SliderContainer from "@/Components/Projects/SliderContainer";
import Card from "./Partials/Card";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { SectionArchetypeType } from "@/Types/key_insight";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { RouteOption } from "@/Context/reducer/projectReducer";
import { progressPortion } from "@/Utils/dispatcher";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { KeyInsightSchema } from "@/Types/key_insight/schema";
import useUser from "@/Hooks/react-query/useUser";
import { useUpdateKeyInsight } from "@/Hooks/react-query/key-insight/useUpdateKeyInsight";
import useKeyInsights from "@/Hooks/react-query/key-insight/useKeyInsight";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { SectionList } from "@/Types/tabs";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import { useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [parsedData, setParsedData] = useState<SectionArchetypeType[]>([]);
  const [editedData, setEditedData] = useState<SectionArchetypeType[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [isGenerate, setIsGenerate] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeItem, setActiveItem] = useState<SectionArchetypeType>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { mutate } = useUpdateKeyInsight();

  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const [isRegenerating, setIsReGenerating] = useState(false);

  const { handleGenerateKeyInsight, handleGenerateChallengeAndTask } =
    useGenerate(dispatch);

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const { data, isLoading, refetch, isRefetching } = useKeyInsights(project);
  const {
    isKeyInsightsComplete,
    isAudienceArchetypeComplete,
    isChallengeAndTaskOfTheBrand,
    isPending: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const routeOptionValue: RouteOption = {
    label: "Generate Challenge & Task",
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
    onClick: () => {
      if (!isAudienceArchetypeComplete.status) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please wait audience archetype is still generating`,
          ),
        );
        return;
      }

      const hasError = hasSelectedKeyInsight();

      if (!hasError) handleRefetchSelectedData();
    },
  };

  const hasSelectedKeyInsight = () => {
    setErrorTabIndex([]);
    const indicesWithoutSelectedKeyInsight = parsedData
      .map((section, index) => {
        const hasSelectedKeyInsight = section.key_insights.some(
          (keyInsight) => keyInsight.is_selected === 1,
        );
        return hasSelectedKeyInsight ? -1 : index;
      })
      .filter((index) => index !== -1);

    if (indicesWithoutSelectedKeyInsight.length) {
      setErrorTabIndex(indicesWithoutSelectedKeyInsight);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedKeyInsight
        .map((index) => `Archetype ${index + 1}`)
        .join(", ");
      countSelectedData();
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select key insight for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();

    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };

  const handleRegenerateButton = (prompt: string) => {
    setParsedData([]);
    setEditedData([]);
    setSelectedIndex([]);
    setIsGenerate(true);
    handleGenerateKeyInsight()(project.slug, prompt);
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const handleFetchSubmit = async (
    payload: KeyInsightSchema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload, id, archetypeId: activeItem?.id!, projectSlug },
      {
        onSuccess: (data) => {
          const keyInsights = data.data;
          const activeItem = keyInsights.find(
            (item: any) => item.id === activeTab,
          );
          setActiveItem(activeItem);

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
      },
    );
  };

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      !differentArchetypes?.is_different_archetype.key_insight &&
      data &&
      data.data &&
      data.data.data &&
      data.data.data.data.length > 0
    ) {
      const { data: keyInsights, sources, total_regenerate } = data.data.data;

      setParsedData(keyInsights);
      setEditedData(keyInsights);
      setTotalRegenerate(total_regenerate);
      setSources(sources);
    }
  }, [data, isPendingDiffArch, differentArchetypes]);

  useEffect(() => {
    if (
      (isKeyInsightsComplete.status && !isKeyInsightsComplete.isQuerying) ||
      parsedData?.length === 0
    ) {
      refetch();
    }

    if (
      isKeyInsightsComplete.status &&
      !isKeyInsightsComplete.isQuerying &&
      parsedData?.length
    ) {
      setTimeout(() => {
        setIsGenerate(false);
      }, 1000);
    }

    if (isKeyInsightsComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isKeyInsightsComplete, isAudienceArchetypeComplete, parsedData]);

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.key_insight
    ) {
      if (
        !isGenerate &&
        !isPendingHistory &&
        !isKeyInsightsComplete.isQuerying
      ) {
        setIsGenerate(true);
        setParsedData([]);
        setEditedData([]);
        dispatch(saveInformation(""));
        dispatch(saveSubInformation(""));
        handleGenerateKeyInsight()(project.slug);
      }
    }
  }, [
    isGenerate,
    differentArchetypes,
    isPendingHistory,
    isKeyInsightsComplete,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/audience-archetype`);
          return;
        },
      }),
    );
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
  }, []);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  const countSelectedData = () => {
    const data = [...parsedData];
    let totalKeyInsights = 0;
    let selectedKeyInsights = 0;

    data.forEach((archetype) => {
      if (archetype.key_insights && Array.isArray(archetype.key_insights)) {
        archetype.key_insights.forEach((keyInsight) => {
          totalKeyInsights++;
          if (keyInsight.is_selected === 1) {
            selectedKeyInsights++;
          }
        });
      }
    });

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        isInactive: selectedKeyInsights > 0 ? false : true,
      }),
    );

    dispatch(
      saveInformation(
        `${selectedKeyInsights} from ${totalKeyInsights} Key Insight selected`,
      ),
    );
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ["challenges", project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/key-insight/check-regenerate`,
    );

    if (data && data.data.is_show_pop_up) {
      setShowRegenerateModal(data.data.is_show_pop_up);
      return;
    }
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/challenge-and-communication-task`);
  };

  useEffect(() => {
    if (!parsedData.length) return;
    const activeItem = parsedData[activeTab];
    const selectedDataWithIndex = activeItem?.key_insights
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);
    }

    countSelectedData();

    setActiveItem(activeItem);
  }, [activeTab, parsedData]);

  useEffect(() => {
    setGoToIndex(-1);
  }, [activeTab]);

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (activeTab < parsedData.length - 1) {
      const nextIndex = activeTab + 1;
      setActiveTab(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        "scrollIntoView" in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (activeTab > 0) {
      const prevIndex = activeTab - 1;
      setActiveTab(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        "scrollIntoView" in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handleRegenerate = () => {
    if (differentArchetypes?.is_different_archetype.key_insight) {
      setIsReGenerating(true);
      setIsGenerate(true);
      handleGenerateKeyInsight()(project.slug, null);
      setIsReGenerating(false);
      setShowRegenerateModal(false);
    } else {
      setIsReGenerating(true);
      if (isChallengeAndTaskOfTheBrand.status)
        handleGenerateChallengeAndTask()(project.slug, null);
      setIsReGenerating(false);
      dispatch(saveInformation(""));
      navigate(`/${project.slug}/challenge-and-communication-task`);
    }
  };

  const sectionList: SectionList = {
    title: "Key Insight",
    value: "",
    section: "key_insights",
  };

  return (
    <Fragment>
      <Helmet title='Key Insight' />
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.keyInsight.progress / 2).toFixed(0)),
            isQuerying: isKeyInsightsComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex md:flex-col flex-row items-center justify-between gap-16 sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col grow'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                Key Insight
              </h1>
              <span className='text-1620 font-normal text-grey-redx'>
                Please{" "}
                <span className='font-bold text-black-redx'>
                  select one or more
                </span>{" "}
                key insights{" "}
                <span className='font-bold text-black-redx'>
                  for each archetype
                </span>
                , or add a new insight by clicking 'Add New Key Insight'.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                isGenerateMore
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                limit={totalRegenerate}
                maxLimit={maxGenerateLimit}
              />
            )}
          </div>

          <TabGroup
            className='pb-80'
            selectedIndex={activeTab}
            onChange={setActiveTab}>
            <div className='relative flex items-center'>
              {!isLoading &&
                !isKeyInsightsComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handlePrev}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={activeTab === 0}>
                    <Icon
                      icon='mingcute:left-line'
                      className='text-25'
                    />
                  </button>
                )}
              <TabList className='flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200'>
                {!isLoading && !isKeyInsightsComplete.isQuerying
                  ? parsedData.map((section, index) => (
                      <Tab
                        disabled={isEditing || isAdding}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className='text-15 px-25 pb-10 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                        key={index}>
                        <div className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center'>
                          <div className='flex flex-col gap-8'>
                            {`Archetype ${index + 1}`}
                            <div className='px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx'>
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              icon='mingcute:information-line'
                              className='text-25 text-error-redx'
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600'>
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isKeyInsightsComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handleNext}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={activeTab === parsedData.length - 1}>
                    <Icon
                      icon='mingcute:right-line'
                      className='text-25'
                    />
                  </button>
                )}
            </div>
            {!isLoading && !isKeyInsightsComplete.isQuerying ? (
              <TabPanels className={"py-24"}>
                {parsedData.map((section, index) => {
                  return (
                    <TabPanel key={index}>
                      <div
                        className='pt-5'
                        key={index}>
                        <SelectedCard
                          title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                          data={[
                            {
                              title: "Archetype Name",
                              content: section.archetype_content.archetype_name,
                            },
                            {
                              title: "Demographic",
                              content: section.archetype_content.demographics,
                            },
                            {
                              title: "Occupation",
                              content: section.archetype_content.occupation,
                            },
                            {
                              title: "Lifestyle",
                              content: section.archetype_content.lifestyle,
                            },
                            {
                              title: "Behavior",
                              content: section.archetype_content.behavior,
                            },
                            {
                              title: "Need & Challenges",
                              content:
                                section.archetype_content.needs_and_challenges,
                            },
                            {
                              title: "Potential Buying Motives",
                              content:
                                section.archetype_content
                                  .potential_buying_motives,
                            },
                            {
                              title: "Current Trends",
                              content: section.archetype_content.current_trends,
                            },
                            {
                              title: "Source of Information",
                              content:
                                section.archetype_content.source_of_information,
                            },
                            {
                              title: "Online Search Behavior",
                              content:
                                section.archetype_content
                                  .online_search_behavior,
                            },
                            {
                              title: "Purchase Frequency",
                              content:
                                section.archetype_content.purchase_frequency,
                            },
                            {
                              title: "Preferred Sales Channels",
                              content:
                                section.archetype_content
                                  .preferred_sales_channels,
                            },
                          ]}
                        />
                        <div className='mt-20' />
                        {(section.key_insights.length > 0 || isAdding) && (
                          <SliderContainer
                            isEditing={isEditing}
                            isAdding={isAdding}
                            length={section.key_insights.length}
                            selectedIndexes={selectedIndex}
                            goToIndex={goToIndex}
                            onActiveIndex={(index) => setActiveIndex(index)}>
                            {!isAdding &&
                              section.key_insights.map((keyInsight, index) => (
                                <Card
                                  id={keyInsight.id}
                                  project={project}
                                  onSelectSuccess={onSelectSuccess}
                                  totalData={section.key_insights.length}
                                  key={index}
                                  item={keyInsight.key_insight_content}
                                  index={index}
                                  isFetching={isRefetching}
                                  isAdding={isAdding}
                                  isEditing={isEditing}
                                  isSelected={!!keyInsight.is_selected}
                                  activeIndex={activeIndex}
                                  ref={(el) => (cardRefs.current[index] = el)}
                                  onSubmitSuccess={handleFetchSubmit}
                                  isCanSelect={isEditor}
                                />
                              ))}

                            {isAdding && (
                              <Card
                                id={-1}
                                onSelectSuccess={onSelectSuccess}
                                project={project}
                                totalData={section.key_insights.length + 1}
                                item={{
                                  consumer: "",
                                  cultural: "",
                                  category: "",
                                }}
                                activeIndex={activeIndex}
                                index={section.key_insights.length}
                                isEditing={isAdding}
                                isAdding={true}
                                isSelected={false}
                                ref={(el) => (cardRefs.current[0] = el)}
                                onSubmitSuccess={handleFetchSubmit}
                              />
                            )}
                          </SliderContainer>
                        )}

                        {section.key_insights.length === 0 &&
                          !isAdding &&
                          !isEditing && (
                            <div className='flex flex-col lg:flex-row gap-55 items-center justify-center min-h-[50vh]'>
                              <div className='my-6'>
                                <img
                                  src='/assets/img/ainstein-notfound.png'
                                  alt='Confused Character'
                                  className='w-48 h-auto mx-auto'
                                />
                              </div>
                              <div className='flex-col gap-30'>
                                <div className='mb-6'>
                                  <h1 className='text-24 font-semibold'>
                                    No result found...
                                  </h1>
                                  <p className='text-gray-400 py-20'>
                                    We couldn't find any results for Key
                                    insight.
                                    <br />
                                    You can input or generate the key insight
                                    Information in this section.
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                          {isEditor &&
                            (!isAdding && !isEditing ? (
                              <button
                                className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                                onClick={toggleIsAdding}>
                                Add Key Insight Manually
                                <Icon
                                  icon='lucide:plus'
                                  className='w-20 h-20'
                                />
                              </button>
                            ) : (
                              <div></div>
                            ))}
                          <div className='flex justify-end items-center gap-x-15'>
                            {isEditor &&
                              (isEditing || isAdding ? (
                                <Fragment>
                                  <button
                                    className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                    onClick={
                                      isAdding
                                        ? toggleIsAdding
                                        : handleCancelEdit
                                    }>
                                    <span className='text-15 font-semibold'>
                                      Cancel
                                    </span>
                                    <Icon
                                      icon='lucide:x'
                                      className='w-20 h-20'
                                    />
                                  </button>
                                  <button
                                    className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer'
                                    onClick={handleSubmit}>
                                    <span className='text-15 font-semibold'>
                                      Save
                                    </span>
                                    <Icon
                                      icon='lucide:save'
                                      className='w-20 h-20'
                                    />
                                  </button>
                                </Fragment>
                              ) : (
                                <EditButton toggleEditing={toggleIsEditing} />
                              ))}
                          </div>
                        </div>
                        <div className='flex w-full pb-60'>
                          {sources && <SourcesList sources={sources} />}
                        </div>
                      </div>
                    </TabPanel>
                  );
                })}
              </TabPanels>
            ) : (
              <div className='block'>
                <div className='w-full h-55 animate-pulse bg-soft-purple-redx rounded-8' />
                <div className='mt-20' />
                <SliderContainer
                  isEditing={isEditing}
                  isAdding={isAdding}
                  length={1}
                  selectedIndexes={selectedIndex}
                  goToIndex={goToIndex}
                  onActiveIndex={undefined}>
                  <Card
                    id={-1}
                    onSelectSuccess={onSelectSuccess}
                    project={project}
                    totalData={-1}
                    item={{
                      consumer: "",
                      cultural: "",
                      category: "",
                    }}
                    isFetching={true}
                    activeIndex={activeIndex}
                    index={-1}
                    isEditing={isAdding}
                    isSelected={false}
                    ref={(el) => (cardRefs.current[-1] = el)}
                    onSubmitSuccess={handleFetchSubmit}
                  />
                </SliderContainer>
                <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                  {!roles.includes("View Only") &&
                    (!isAdding && !isEditing ? (
                      <button
                        className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                        onClick={undefined}>
                        Add Key Insight Manually
                        <Icon
                          icon='lucide:plus'
                          className='w-20 h-20'
                        />
                      </button>
                    ) : (
                      <div></div>
                    ))}
                  <div className='flex justify-end items-center gap-x-15'>
                    {!roles.includes("View Only") &&
                      (isEditing || isAdding ? (
                        <Fragment>
                          <button
                            className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>
                              Cancel
                            </span>
                            <Icon
                              icon='lucide:x'
                              className='w-20 h-20'
                            />
                          </button>
                          <button
                            disabled={false}
                            className={`inline-flex items-center gap-x-4 ${
                              false ? "text-gray-600" : "text-blue-redx"
                            } cursor-pointer`}
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>Save</span>
                            <Icon
                              icon='lucide:save'
                              className='w-20 h-20'
                            />
                          </button>
                        </Fragment>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className='flex w-full pb-60'>
                  <div className='inline-flex gap-10'>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerate}
        onClose={() => setShowRegenerateModal(false)}
      />
    </Fragment>
  );
};

export default Index;
