import ProjectLayout from "@/Layouts/ProjectLayout";
import CreativeIdeaIndex from "./Index";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import useProjectDetail from "@/Hooks/react-query/useProjectDetail";

const Wrapper = () => {
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? "",
  );

  return (
    <ProjectLayout>
      <Helmet>
        <title>Idea Alignment</title>
      </Helmet>
      {!isLoading && project && <CreativeIdeaIndex project={project} />}{" "}
    </ProjectLayout>
  );
};

export default Wrapper;
