import { fetcher } from "@/Services/axios";
import { IdeaPersonalizationResponse } from "@/Types/idea_personalization";

import { useQuery } from "@tanstack/react-query";

const fetch = async (
  project_slug: string,
): Promise<IdeaPersonalizationResponse> => {
  try {
    const response = await fetcher.get(
      `${project_slug}/opportunity-among-competition/lists`, // todo: change with correct url
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        "Failed to fetch idea personalization data",
    );
  }
};

const useIdeaPersonalization = (project_slug: string) => {
  return useQuery<IdeaPersonalizationResponse, Error>({
    queryKey: ["ideaPersonalization", project_slug],
    queryFn: () => fetch(project_slug),
    refetchOnWindowFocus: false,
  });
};

export default useIdeaPersonalization;
