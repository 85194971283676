import React, { Fragment, useMemo } from "react";
import { Icon } from "@iconify/react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";

import { z } from "zod";
import TextArea from "@/Components/TextAreaInput";
import InputLabel from "@/Components/InputLabel";
import InputError from "@/Components/InputError";
import { zodResolver } from "@hookform/resolvers/zod";

const RegenerateSchema = z.object({
  prompt: z.string().min(3, "Minimum length is 3 characters"),
});

type Regenerate = z.infer<typeof RegenerateSchema>;

const RegenButton: React.FC<{
  limit: number;
  maxLimit: number;
  isGenerateMore?: boolean;
}> = ({ limit, maxLimit, isGenerateMore = false }) => {
  return (
    <Fragment>
      <span
        className={`text-15 inline-block font-semibold ${
          limit < maxLimit
            ? "bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] text-transparent bg-clip-text"
            : "text-placeholder-redx"
        }`}>
        {isGenerateMore ? "Generate More" : "Regenerate"}
      </span>
      <Icon
        icon='lucide:wand-sparkles'
        className={`w-18 h-18 ${
          limit < maxLimit ? "text-blue-redx" : "text-placeholder-redx"
        }`}
      />
      <div className='inline-flex p-4 rounded-4 bg-soft-purple-redx font-bold text-12 leading-none text-blue-redx'>
        {limit}/{maxLimit}
      </div>
    </Fragment>
  );
};

const RegenerateButton: React.FC<{
  limit: number;
  maxLimit: number;
  isGenerateMore?: boolean;
  text?: string;
  onSubmit: (data: Regenerate) => void;
}> = ({ limit, maxLimit = 2, onSubmit, isGenerateMore = false }) => {
  const defaultValues: Regenerate = useMemo(
    () => ({
      prompt: "",
    }),
    [],
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<Regenerate>({
    resolver: zodResolver(RegenerateSchema),
    defaultValues,
    mode: "all",
  });

  const onFormSubmit = (data: Regenerate) => {
    onSubmit(data);
  };

  return (
    <Popover className='relative'>
      <PopoverButton className='inline-flex items-center gap-x-4'>
        <RegenButton
          isGenerateMore={isGenerateMore}
          limit={limit}
          maxLimit={maxLimit}
        />
      </PopoverButton>
      <Transition
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'>
        <PopoverPanel
          anchor={{ to: "bottom end", gap: -25, offset: 2 }}
          className='md:w-full lg:min-w-525 !max-w-525 z-30 divide-y rounded-lg bg-white shadow-lg p-15'>
          {({ close }) =>
            limit < maxLimit ? (
              <Fragment>
                <InputLabel
                  htmlFor='prompt'
                  value='Let us know what kind of output you wanted'
                  className='!text-black-redx text-12 font-semibold'
                />
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <div className='mb-15'>
                    <Controller
                      control={control}
                      name='prompt'
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <>
                            <TextArea
                              containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                              className='block w-full !text-14'
                              placeholder='Example: I want the output talk about the product in specific context.'
                              isFocused={false}
                              rows={3}
                              onChange={onChange}
                              value={value}
                            />
                            {error?.message && (
                              <InputError
                                message={error?.message}
                                className='mt-2'
                              />
                            )}
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className='flex w-full justify-end gap-x-15'>
                    <button
                      type='button'
                      className='text-15 text-error-redx font-semibold'
                      onClick={() => {
                        reset();
                        close();
                      }}>
                      Cancel
                    </button>
                    <button
                      type='submit'
                      disabled={!isValid}
                      className={`inline-flex items-center gap-x-4 ${
                        isValid ? "cursor-pointer" : "cursor-not-allowed"
                      }`}>
                      <RegenButton
                        isGenerateMore={isGenerateMore}
                        limit={limit}
                        maxLimit={maxLimit}
                      />
                    </button>
                  </div>
                </form>
              </Fragment>
            ) : (
              <Fragment>
                <InputLabel
                  htmlFor='prompt'
                  value='Generation limit reached'
                  className='!text-black-redx text-14 font-semibold mb-8'
                />
                <p className='block w-full !text-14 text-grey-redx mb-15 leading-tight'>
                  You have reached the generation limit for this section. Click
                  the feedback button on the bottom-left side to request
                  additional regenerations.
                </p>
                <div className='flex w-full justify-end gap-x-15'>
                  <button
                    className='text-15 px-14 py-10 text-white bg-blue-redx rounded-8 font-semibold'
                    onClick={() => {
                      reset();
                      close();
                    }}>
                    Understand
                  </button>
                </div>
              </Fragment>
            )
          }
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default RegenerateButton;
