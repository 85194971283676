import { Dispatch } from "react";
import axios from "axios";

import {
  ProjectAction,
  setProductResearchLoading,
  setMarketResearchLoading,
  setMarketResearchDemand,
  setMarketResearchDemandLoading,
  setMarketResearchTrends,
  setMarketResearchTrendsLoading,
} from "../actions/projectActions";
import {
  handleGenerateTargetAudienceAudienceInsight,
  handleGenerateTargetAudienceBehavioralTrend,
  handleGenerateTargetAudienceMediaConsumptionPatterns,
  handleGenerateTargetAudiencePerceptionAnalysis,
} from "@/Context/dispatcher/target_audience";
import {
  handleEditedTargetAudienceAudienceInsights,
  handleSetTargetAudienceAudienceInsights,
} from "@/Context/dispatcher/target_audience/audience_insights";
import {
  handleEditedTargetAudiencePerceptionAnalysis,
  handleSetTargetAudiencePerceptionAnalysis,
} from "@/Context/dispatcher/target_audience/perception_analysis";
import {
  handleEditedTargetAudienceBehavioralTrends,
  handleSetTargetAudienceBehavioralTrends,
} from "@/Context/dispatcher/target_audience/behavioral_trends";
import {
  handleEditedTargetAudienceMediaConsumptionPatterns,
  handleSetTargetAudienceMediaConsumptionPatterns,
} from "@/Context/dispatcher/target_audience/media_consumption_patterns";
import {
  handleGenerateKeyInsight,
  handleEditedKeyInsight,
  handleSetKeyInsightData,
} from "@/Context/dispatcher/key_insight";

import {
  handleSetKeyTouchPointData,
  handleGenerateKeyTouchPoint,
  handleEditedKeyTouchPoint,
} from "@/Context/dispatcher/key_touch_points";

import {
  handleGenerateOnePageStrategy,
  handleSetOnePageStrategyData,
  handleEditedOnePageStrategy,
} from "@/Context/dispatcher/one_page_strategy";

import {
  handleGenerateAudienceArchetype,
  handleSetAudienceArchetypeData,
} from "@/Context/dispatcher/audience_archetype";

import {
  handleGenerateChallengeAndTask,
  handleSetChallengeAndTaskData,
} from "@/Context/dispatcher/challenge_and_task";

import {
  handleGenerateCompetitorAnalysis,
  handleEditedCompetitorAnalysis,
  handleSetCompetitorAnalysisData,
} from "@/Context/dispatcher/competitor_analysis";
import {
  handleGenerateOpportunityAmongCompetition,
  handleEditedOpportunityAmongCompetition,
  handleSetOpportunityAmongCompetitionData,
} from "@/Context/dispatcher/opportunity_among_competition";

import { handleGenerateIdeaAlignment } from "@/Context/dispatcher/idea_alignment";

import {
  handleGenerateProductResearchMainFeatures,
  handleGenerateProductResearchMarcommHighlights,
  handleGenerateProductResearchProductDescription,
  handleSetProductResearchMainFeaturesData,
  handleSetProductResearchMarcommHighlightsData,
  handleSetProductResearchProductDescriptionData,
} from "@/Context/dispatcher/product_research";

import { fetcher } from "@/Services/axios";

export const handleGenerateProductResearch =
  (dispatch: Dispatch<ProjectAction>) => async (project_slug: string) => {
    try {
      dispatch(
        setProductResearchLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setProductResearchLoading({
            status: true,
            progress,
          }),
        ),
      );
      await fetcher.post(
        `${project_slug}/product-research/generate/product-description`,
        {},
      );

      dispatch(
        setProductResearchLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setProductResearchLoading({
            status: false,
            progress: 100,
          }),
        );
      }, 1000);
      clearInterval(interval);
    } catch {
      dispatch(
        setProductResearchLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateMarketResearch =
  (dispatch: Dispatch<ProjectAction>) => async (project_slug: string) => {
    try {
      dispatch(
        setMarketResearchLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setMarketResearchLoading({
            status: true,
            progress,
          }),
        ),
      );
      await fetcher.post(`/${project_slug}/market-research/generate`, {});
      clearInterval(interval);
      dispatch(
        setMarketResearchLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setMarketResearchLoading({
            status: false,
            progress: 100,
          }),
        );
      }, 1000);
    } catch {
      dispatch(
        setMarketResearchLoading({
          status: false,
          progress: 100,
        }),
      );
    }
  };

const handleProgress = (dispatch: (progress: number) => void) => {
  let current_progress = 0;
  let step = 0.5;
  let progress = 0;
  const interval = setInterval(() => {
    current_progress += step;
    progress =
      Math.round((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1000) /
      1000;
    progress = Math.round(progress * 10) / 10;
    if (progress >= 100) {
      clearInterval(interval);
    } else if (progress >= 70) {
      step = 0.2;
    }
    if (dispatch) {
      dispatch(progress);
    }
  }, 1000);
  return interval;
};

export const handleGenerateMarketDemand =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    try {
      dispatch(
        setMarketResearchDemandLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setMarketResearchDemandLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/market-research/generate/demand`,
        {
          prompt: regenerate_prompt,
        },
      );
      clearInterval(interval);
      dispatch(
        setMarketResearchDemandLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setMarketResearchDemandLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setMarketResearchDemand(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setMarketResearchDemandLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateMarketTrends =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    try {
      dispatch(
        setMarketResearchTrendsLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setMarketResearchTrendsLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/market-research/generate/trends`,
        {
          prompt: regenerate_prompt,
        },
      );
      clearInterval(interval);
      dispatch(
        setMarketResearchTrendsLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setMarketResearchTrendsLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setMarketResearchTrends(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setMarketResearchTrendsLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const useGenerate = (dispatch: Dispatch<ProjectAction>) => ({
  handleGenerateProductResearch: handleGenerateProductResearch.bind(
    null,
    dispatch,
  ),
  handleGenerateMarketResearch: handleGenerateMarketResearch.bind(
    null,
    dispatch,
  ),

  handleGenerateProductResearchProductDescription:
    handleGenerateProductResearchProductDescription.bind(null, dispatch),
  handleSetProductResearchProductDescriptionData:
    handleSetProductResearchProductDescriptionData.bind(null, dispatch),

  handleGenerateProductResearchMainFeatures:
    handleGenerateProductResearchMainFeatures.bind(null, dispatch),
  handleSetProductResearchMainFeaturesData:
    handleSetProductResearchMainFeaturesData.bind(null, dispatch),

  handleGenerateProductResearchMarcommHighlights:
    handleGenerateProductResearchMarcommHighlights.bind(null, dispatch),
  handleSetProductResearchMarcommHighlightsData:
    handleSetProductResearchMarcommHighlightsData.bind(null, dispatch),

  handleGenerateMarketDemand: handleGenerateMarketDemand.bind(null, dispatch),
  handleGenerateMarketTrends: handleGenerateMarketTrends.bind(null, dispatch),

  // challenge and task
  handleGenerateChallengeAndTask: handleGenerateChallengeAndTask.bind(
    null,
    dispatch,
  ),

  handleSetChallengeAndTaskData: handleSetChallengeAndTaskData.bind(
    null,
    dispatch,
  ),

  // Target Audience
  handleGenerateTargetAudienceAudienceInsight:
    handleGenerateTargetAudienceAudienceInsight.bind(null, dispatch),
  handleGenerateTargetAudienceBehavioralTrend:
    handleGenerateTargetAudienceBehavioralTrend.bind(null, dispatch),
  handleGenerateTargetAudienceMediaConsumptionPatterns:
    handleGenerateTargetAudienceMediaConsumptionPatterns.bind(null, dispatch),
  handleGenerateTargetAudiencePerceptionAnalysis:
    handleGenerateTargetAudiencePerceptionAnalysis.bind(null, dispatch),

  // Target Audience audience insight
  handleEditedTargetAudienceAudienceInsights:
    handleEditedTargetAudienceAudienceInsights.bind(null, dispatch),
  handleSetTargetAudienceAudienceInsights:
    handleSetTargetAudienceAudienceInsights.bind(null, dispatch),

  // Target Audience perception analysis
  handleEditedTargetAudiencePerceptionAnalysis:
    handleEditedTargetAudiencePerceptionAnalysis.bind(null, dispatch),
  handleSetTargetAudiencePerceptionAnalysis:
    handleSetTargetAudiencePerceptionAnalysis.bind(null, dispatch),

  // Target Audience behavioral trends
  handleEditedTargetAudienceBehavioralTrends:
    handleEditedTargetAudienceBehavioralTrends.bind(null, dispatch),
  handleSetTargetAudienceBehavioralTrends:
    handleSetTargetAudienceBehavioralTrends.bind(null, dispatch),

  // Target Audience media consumption patterns
  handleEditedTargetAudienceMediaConsumptionPatterns:
    handleEditedTargetAudienceMediaConsumptionPatterns.bind(null, dispatch),
  handleSetTargetAudienceMediaConsumptionPatterns:
    handleSetTargetAudienceMediaConsumptionPatterns.bind(null, dispatch),

  // OnePageStrategy
  handleGenerateOnePageStrategy: handleGenerateOnePageStrategy.bind(
    null,
    dispatch,
  ),
  handleSetOnePageStrategyData: handleSetOnePageStrategyData.bind(
    null,
    dispatch,
  ),
  handleEditedOnePageStrategy: handleEditedOnePageStrategy.bind(null, dispatch),

  // Key Insight
  handleGenerateKeyInsight: handleGenerateKeyInsight.bind(null, dispatch),
  handleEditedKeyInsight: handleEditedKeyInsight.bind(null, dispatch),
  handleSetKeyInsightData: handleSetKeyInsightData.bind(null, dispatch),

  // audience archetype
  handleGenerateAudienceArchetype: handleGenerateAudienceArchetype.bind(
    null,
    dispatch,
  ),

  handleSetAudienceArchetypeData: handleSetAudienceArchetypeData.bind(
    null,
    dispatch,
  ),

  // Competitor Analysis
  handleGenerateCompetitorAnalysis: handleGenerateCompetitorAnalysis.bind(
    null,
    dispatch,
  ),
  handleEditedCompetitorAnalysis: handleEditedCompetitorAnalysis.bind(
    null,
    dispatch,
  ),
  handleSetCompetitorAnalysisData: handleSetCompetitorAnalysisData.bind(
    null,
    dispatch,
  ),

  // Opportunity Among Competition
  handleGenerateOpportunityAmongCompetition:
    handleGenerateOpportunityAmongCompetition.bind(null, dispatch),
  handleEditedOpportunityAmongCompetition:
    handleEditedOpportunityAmongCompetition.bind(null, dispatch),
  handleSetOpportunityAmongCompetitionData:
    handleSetOpportunityAmongCompetitionData.bind(null, dispatch),

  // Key Touch Point
  handleGenerateKeyTouchPoint: handleGenerateKeyTouchPoint.bind(null, dispatch),
  handleSetKeyTouchPointData: handleSetKeyTouchPointData.bind(null, dispatch),
  handleEditedKeyTouchPoint: handleEditedKeyTouchPoint.bind(null, dispatch),

  // idea alignment
  handleGenerateIdeaAlignment: handleGenerateIdeaAlignment.bind(null, dispatch),
});
