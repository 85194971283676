import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { CreativeIdea } from "@/Types/creative-idea/creative-idea";

const post = async ({
  projectSlug,
  payload,
}: {
  projectSlug: string;
  payload: CreativeIdea;
}) => {
  try {
    const { data } = await fetcher.post(`/${projectSlug}/idea-submission`, {
      title: payload.title,
      description: payload.description,
    });

    return data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useUpdateCreative = () => {
  return useMutation<
    any,
    Error,
    { projectSlug: string; payload: CreativeIdea }
  >({
    mutationFn: post,
  });
};

export { useUpdateCreative };
