import { fetcher } from "@/Services/axios";
import { IdeaAlignmentResponse } from "@/Types/creative-idea-alignment";

import { useQuery } from "@tanstack/react-query";

const fetch = async (project_slug: string): Promise<IdeaAlignmentResponse> => {
  try {
    const response = await fetcher.get(
      `${project_slug}/idea-alignment/lists`, // todo: change with correct url
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message ||
        "Failed to fetch idea personalization data",
    );
  }
};

const useIdeaAlignments = (project_slug: string) => {
  return useQuery<IdeaAlignmentResponse, Error>({
    queryKey: ["ideaAlignment", project_slug],
    queryFn: () => fetch(project_slug),
    refetchOnWindowFocus: false,
  });
};

export default useIdeaAlignments;
