import { forwardRef, useContext, useState } from "react";
import { Icon } from "@iconify/react";

import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import InputDropdown from "@/Components/InputDropdown";
import { ProjectContext } from "@/Context/ProjectContext";
import RegenerateModal from "./RegenerateModal";
import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { SubmissionFormPayload } from "@/Types/form";

interface MarketInformationProps {
  data: {
    product_category: string;
    product_subcategory: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isEditor: boolean;
  isFetching: boolean;
  categories: { title: string; value: number }[];
  subcategories: { title: string; value: number }[];
}

const MarketInformation = forwardRef<HTMLDivElement, MarketInformationProps>(
  (props, ref) => {
    const {
      data,
      setData,
      errors,
      setError,
      isActive,
      categories,
      subcategories,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
    } = props;
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(1);
      if (!isNotError) {
        setIsEditing(true);
        return;
      }
      setIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`flex flex-col w-full mb-24 ${
          isActive ? "" : "opacity-30 cursor-not-allowed"
        }`}>
        <div className='flex justify-between items-center w-full'>
          <h1 className='font-bold text-24 leading-32 mb-15'>
            Market Information
          </h1>
          {!isFetching &&
            state.submission.is_market_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <Icon
                icon='lucide:pencil-line'
                className='text-blue-redx w-16 h-16 cursor-pointer'
                onClick={() => setIsEditing(true)}
              />
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div className='inline-flex gap-8'>
              <button
                className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                onClick={() => setIsEditing(false)}>
                <span className='text-15 font-semibold'>Cancel</span>
                <Icon
                  icon='lucide:x'
                  className='size-16'
                />
              </button>

              <button
                className='inline-flex items-center gap-x-4 cursor-pointer'
                onClick={() => setIsModalOpen(true)}>
                <p className='text-14 font-semibold text-blue-redx'>Save</p>
                <Icon
                  icon='lucide:save'
                  className='text-blue-redx size-16'
                />
              </button>
            </div>
          )}
        </div>
        <div className='grid grid-cols-2 w-full gap-24'>
          <div>
            <InputLabel
              htmlFor='product_category'
              value='Product Category'
              className='!text-black-redx'
            />
            {isFetching ? (
              <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8' />
            ) : (!state.submission.is_market_information_complete ||
                isEditing) &&
              isEditor ? (
              <InputDropdown
                modelValue={data.product_category}
                list={categories}
                state='init'
                multiple={false}
                withFilter={true}
                error={errors.product_category?.message}
                containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                placeholder='Choose product category here'
                disabled={!isActive}
                onChange={(value: any) => {
                  setData("product_category", value);
                  setData("product_subcategory", "");
                  setError("product_category", { message: "" });
                  setError("product_subcategory", { message: "" });
                }}
              />
            ) : (
              <p className='!text-14 px-0 py-6'>
                {Number(data.product_category) > 0
                  ? categories.find(
                      (listItem) =>
                        listItem.value === Number(data.product_category),
                    )?.title
                  : "-"}
              </p>
            )}

            <InputError
              message={errors.product_category?.message}
              className='mt-2'
            />
          </div>
          <div>
            <InputLabel
              htmlFor='product_subcategory'
              value='Product Subcategory'
              className='!text-black-redx'
            />
            {isFetching ? (
              <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8' />
            ) : (!state.submission.is_market_information_complete ||
                isEditing) &&
              isEditor ? (
              <InputDropdown
                modelValue={data.product_subcategory}
                list={subcategories}
                state='init'
                multiple={false}
                withFilter={true}
                error={errors.product_subcategory?.message}
                containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
                placeholder='Choose product subcategory here'
                disabled={!isActive}
                onChange={(value: any) => {
                  setData("product_subcategory", value);
                  setError("product_subcategory", { message: "" });
                }}
              />
            ) : (
              <p className='!text-14 px-0 py-6'>
                {Number(data.product_subcategory) > 0
                  ? subcategories.find(
                      (listItem) =>
                        listItem.value === Number(data.product_subcategory),
                    )?.title
                  : "-"}
              </p>
            )}

            <InputError
              message={errors.product_subcategory?.message}
              className='mt-2'
            />
          </div>
        </div>
        <RegenerateModal
          isOpen={isModalOpen}
          onClose={() => {
            fetchData();
            setIsEditing(false);
            setIsModalOpen(false);
          }}
          handleSubmit={submitForm}
        />
      </div>
    );
  },
);

export default MarketInformation;
