import Wrapper from "@/Pages/CreativeIdea/Wrapper";

const CreativeIdeaRoutes = [
  {
    path: "/:project_slug/creative-idea",
    element: <Wrapper />,
  },
];

export default CreativeIdeaRoutes;
