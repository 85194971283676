import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveInformation,
  saveRouteNext,
  saveRoutePrev,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import GenerateLoading from "@/Components/GenerateLoading";
import SourcesList from "@/Components/Projects/SourcesList";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import Card from "./Partials/Card";
import { useGenerate } from "@/Context/hooks/useGenerate";
import {
  OpportunityAmongCompetitionProps,
  OpportunityData,
} from "@/Types/opportunity_among_competition";
import useRolesBadge from "@/Hooks/useRolesBadge";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { progressPortion } from "@/Utils/dispatcher";
import useUser from "@/Hooks/react-query/useUser";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import SliderContainer from "@/Components/Projects/SliderContainer";
import SelectedCard from "@/Components/SelectedCard";
import EditButton from "@/Components/Projects/EditButton";
import useOpportunities from "@/Hooks/react-query/opportunity/useGetList";
import { setPanelInformation } from "@/Utils/projects/archetype";
import { Archetype } from "@/Types/audience-archetype";
import { useUpdateOpportunities } from "@/Hooks/react-query/opportunity/useUpdate";
import toast from "react-hot-toast";
import { RouteOption } from "@/Context/reducer/projectReducer";
import { SectionList } from "@/Types/tabs";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import { useQueryClient } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";
import ErrorLLM from "@/Components/Toast/ErrorLLM";

export type CardHandles = {
  submitForm: () => void;
};

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useContext(ProjectContext);
  const [editedData, setEditedData] = useState<OpportunityData[]>([]);
  const [parsedData, setParsedData] = useState<OpportunityData[]>([]);
  const [activeArchetype, setActiveArchetype] = useState<OpportunityData>();
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [errorTabIndex, setErrorTabIndex] = useState<number[]>();
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [selectedOpportunityIndex, setSelectedOpportunityIndex] = useState<
    number[] | undefined
  >();
  const maxGenerateLimit = project.max_generated_data;
  const navigate = useNavigate();
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const {
    isOpportunityAmongCompetitionComplete,
    isPending: isPendingOpportunity,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);
  const { data, isLoading, refetch, isRefetching } = useOpportunities(
    project.slug,
  );
  const { mutate } = useUpdateOpportunities();

  const routeOptionValue: RouteOption = {
    label: "Generate One-Page Strategy",
    isActive: true,
    isGenerate: true,
    isInactive: true,
    onClick: () => {
      if (!differentArchetypes?.is_selected_content.challenges) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please select archetype in Challenge & Communication Task first`,
          ),
        );
        return;
      }

      const hasError = hasSelectedOpportunity();

      if (!hasError) handleRefetchSelectedData();
    },
  };

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");
  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);

  const handleCancelEdit = async () => {
    toggleIsEditing();
    setTimeout(() => {
      setGoToIndex(-1);
    }, 1000);
  };
  const cardRefs = useRef<(CardHandles | null)[]>([]);

  const {
    handleGenerateOpportunityAmongCompetition,
    handleGenerateOnePageStrategy,
  } = useGenerate(dispatch);

  const { data: differentArchetypes, isPending: isPendingDiffArch } =
    useDifferentArchetypes(project);

  const handleSaveEditedData = (
    updatedData: OpportunityAmongCompetitionProps,
    index: number,
  ) => {
    const { id, is_selected, ...opportunities_content } = updatedData;

    const payload = {
      opportunities_content: opportunities_content,
      id: id,
      archetype_id: activeArchetype!.id,
    };

    setUpdateLoading(true);
    mutate(
      {
        payload: payload,
        projectSlug: project.slug,
      },
      {
        onSuccess: () => {
          refreshData();

          isAdding &&
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
        },
        onError: (error) => {
          toast.error(error.message);
          refreshData();
        },
      },
    );
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ["OnePageStrategy", project.slug],
      exact: true,
    });

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/opportunity-among-competition/check-regenerate`,
    );

    if (data && data.data.is_show_pop_up) {
      setShowRegenerateModal(data.data.is_show_pop_up);
      return;
    }

    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    navigate(`/${project.slug}/one-page-strategy`);
  };

  const hasSelectedOpportunity = () => {
    const indicesWithoutSelectedIdea = parsedData
      .map((section, index) => {
        const hasSelectedIdea = section.opportunities.some(
          (opportunity) => opportunity.is_selected,
        );
        return hasSelectedIdea ? -1 : index;
      })
      .filter((index) => index !== -1);
    if (indicesWithoutSelectedIdea.length) {
      setErrorTabIndex(indicesWithoutSelectedIdea);
      const namesWithoutSelectedKeyInsight = indicesWithoutSelectedIdea
        .map((index) => `Archetype ${index + 1}`)
        .join(", ");
      countSelectedData();
      dispatch(
        saveSubInformation(
          `{text-error-redx} Please select Idea for ${namesWithoutSelectedKeyInsight}`,
        ),
      );
      return true;
    }
    dispatch(saveSubInformation(``));
    return false;
  };

  const handleRegenerateButton = (prompt: string) => {
    setParsedData([]);
    setIsGenerated(true);
    handleGenerateOpportunityAmongCompetition()(project.slug, prompt);
  };

  const handleRegenerateNextProcess = () => {
    setIsReGenerating(true);
    handleGenerateOnePageStrategy()(project.slug, null);
    setIsReGenerating(false);
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
    navigate(`/${project.slug}/one-page-strategy`);
  };

  const tabsRef = useRef([]);

  const handleNext = () => {
    if (selectedIndex < parsedData.length - 1) {
      const nextIndex = selectedIndex + 1;
      setSelectedIndex(nextIndex);
      if (
        tabsRef.current[nextIndex] &&
        "scrollIntoView" in tabsRef.current[nextIndex]
      ) {
        (tabsRef.current[nextIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handlePrev = () => {
    if (selectedIndex > 0) {
      const prevIndex = selectedIndex - 1;
      setSelectedIndex(prevIndex);
      if (
        tabsRef.current[prevIndex] &&
        "scrollIntoView" in tabsRef.current[prevIndex]
      ) {
        (tabsRef.current[prevIndex] as Element).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const refreshData = () => {
    isAdding && toggleIsAdding();
    isEditing && toggleIsEditing();

    setTimeout(() => {
      refetch();
    }, 700);

    setUpdateLoading(false);
  };

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/competitor-analysis`);
        },
      }),
    );
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
  }, []);

  useEffect(() => {
    setGoToIndex(-1);
  }, [selectedIndex]);

  const countSelectedData = () => {
    const data = [...parsedData];
    let totalKeyInsights = 0;
    let selectedKeyInsights = 0;

    data.forEach((idea) => {
      if (idea.opportunities && Array.isArray(idea.opportunities)) {
        idea.opportunities.forEach((opportunity) => {
          totalKeyInsights++;
          if (opportunity.is_selected) {
            selectedKeyInsights++;
          }
        });
      }
    });

    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        isInactive: selectedKeyInsights > 0 ? false : true,
      }),
    );

    dispatch(
      saveInformation(
        `${selectedKeyInsights} from ${totalKeyInsights} Idea selected`,
      ),
    );
  };

  useEffect(() => {
    if (
      !isPendingDiffArch &&
      !differentArchetypes?.is_different_archetype.opportunities &&
      data?.data
    ) {
      const {
        data: selectedArchetypesOpportunity,
        sources,
        total_regenerate,
      } = data;

      setEditedData(selectedArchetypesOpportunity);
      setParsedData(selectedArchetypesOpportunity);
      setTotalRegenerate(total_regenerate);
      setSources(sources);
    }
  }, [data, differentArchetypes, isPendingDiffArch]);

  useEffect(() => {
    if (
      !isOpportunityAmongCompetitionComplete.isQuerying &&
      isOpportunityAmongCompetitionComplete.status
    ) {
      refetch();
    }

    if (
      !isOpportunityAmongCompetitionComplete.isQuerying &&
      isOpportunityAmongCompetitionComplete.status &&
      parsedData?.length
    ) {
      setTimeout(() => {
        setIsGenerated(false);
      }, 1000);
    }

    if (isOpportunityAmongCompetitionComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isOpportunityAmongCompetitionComplete, parsedData]);

  useEffect(() => {
    if (
      !isGenerated &&
      !isPendingDiffArch &&
      differentArchetypes &&
      differentArchetypes.is_different_archetype.opportunities
    ) {
      if (
        !isGenerated &&
        !isPendingOpportunity &&
        !isOpportunityAmongCompetitionComplete.isQuerying
      ) {
        setIsGenerated(true);
        setParsedData([]);
        setEditedData([]);
        dispatch(saveInformation(""));
        dispatch(saveSubInformation(""));
        handleGenerateOpportunityAmongCompetition()(project.slug);
      }
    }
  }, [
    isGenerated,
    differentArchetypes,
    isOpportunityAmongCompetitionComplete,
    isPendingOpportunity,
    isPendingDiffArch,
  ]);

  useEffect(() => {
    if (!parsedData.length) return;

    const activeArchetype = parsedData[selectedIndex];
    const selectedDataWithIndex = activeArchetype?.opportunities
      .map((item, index) => ({ item, index }))
      .filter(({ item }) => item.is_selected === 1);

    if (selectedDataWithIndex) {
      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedOpportunityIndex(indices);
    }

    setActiveArchetype(activeArchetype);
    countSelectedData();
  }, [selectedIndex, parsedData]);

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  const sectionList: SectionList = {
    title: "Opportunity Among Competition",
    value: "",
    section: "opportunity_among_competition",
  };

  return (
    <Fragment>
      <title title='Opportunity Among Competition ' />
      {isGenerated ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number(
              (state.opportunityAmongCompetition.progress / 2).toFixed(0),
            ),
            isQuerying: isOpportunityAmongCompetitionComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex items-center justify-between sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col w-2/3'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                Opportunity Among Competition
              </h1>
              <span className='text-15 font-normal text-grey-redx'>
                Please review and edit this information, considering that the
                provided reference may not always be accurate.
              </span>
            </div>
            {isEditor && (
              <RegenerateButton
                onSubmit={(form) => handleRegenerateButton(form.prompt)}
                limit={totalRegenerate}
                isGenerateMore={true}
                maxLimit={maxGenerateLimit}
              />
            )}
          </div>

          <TabGroup
            className='pb-80'
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}>
            <div className='relative flex items-center'>
              {!isLoading &&
                !isOpportunityAmongCompetitionComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handlePrev}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={selectedIndex === 0}>
                    <Icon
                      icon='mingcute:left-line'
                      className='text-25'
                    />
                  </button>
                )}
              <TabList
                className={
                  "flex w-full overflow-x-auto scrollbar-hide border-b-1 border-grey-200"
                }>
                {!isLoading && !isOpportunityAmongCompetitionComplete.isQuerying
                  ? parsedData.map((section, index) => (
                      <Tab
                        disabled={isEditing || isAdding}
                        ref={(el: HTMLElement | null) => {
                          if (el) {
                            (tabsRef.current as HTMLElement[])[index] = el;
                          }
                        }}
                        className='text-15 px-25 pb-10 text-black-redx min-w-5/24 w-full md:min-w-7/12 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                        key={index}>
                        <div className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none inline-flex gap-8 items-center justify-center'>
                          <div className='flex flex-col gap-8'>
                            {`Archetype ${index + 1}`}
                            <div className='px-8 py-4 bg-soft-grey-redx rounded-8 font-semibold text-black-redx'>
                              {section.archetype_content.archetype_name}
                            </div>
                          </div>
                          {errorTabIndex && errorTabIndex.includes(index) && (
                            <Icon
                              icon='mingcute:information-line'
                              className='text-25 text-error-redx'
                            />
                          )}
                        </div>
                      </Tab>
                    ))
                  : Array.from({ length: 3 }).map((_, index) => (
                      <button
                        key={`dummy-${index}`}
                        className='h-78 w-full py-2 -mb-px text-sm font-medium focus:outline-none flex flex-col gap-8 items-center justify-center text-gray-600 hover:text-indigo-600'>
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                        <div className='w-155 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
                      </button>
                    ))}
              </TabList>
              {!isLoading &&
                !isOpportunityAmongCompetitionComplete.isQuerying &&
                parsedData.length > 3 && (
                  <button
                    onClick={handleNext}
                    className='p-2 text-gray-500 hover:text-black'
                    disabled={selectedIndex === parsedData.length - 1}>
                    <Icon
                      icon='mingcute:right-line'
                      className='text-25'
                    />
                  </button>
                )}
            </div>
            {!isLoading && !isOpportunityAmongCompetitionComplete.isQuerying ? (
              <TabPanels className={"py-24"}>
                {parsedData.map((section, index) => (
                  <TabPanel key={index}>
                    <div
                      className='pt-5'
                      key={index}>
                      <SelectedCard
                        title={`Archetype Detail - ${section.archetype_content.archetype_name}`}
                        data={[
                          {
                            title: "Archetype Name",
                            content: section.archetype_content.archetype_name,
                          },
                          {
                            title: "Demographic",
                            content: section.archetype_content.demographics,
                          },
                          {
                            title: "Occupation",
                            content: section.archetype_content.occupation,
                          },
                          {
                            title: "Lifestyle",
                            content: section.archetype_content.lifestyle,
                          },
                          {
                            title: "Behavior",
                            content: section.archetype_content.behavior,
                          },
                          {
                            title: "Need & Challenges",
                            content:
                              section.archetype_content.needs_and_challenges,
                          },
                          {
                            title: "Potential Buying Motives",
                            content:
                              section.archetype_content
                                .potential_buying_motives,
                          },
                          {
                            title: "Current Trends",
                            content: section.archetype_content.current_trends,
                          },
                          {
                            title: "Source of Information",
                            content:
                              section.archetype_content.source_of_information,
                          },
                          {
                            title: "Online Search Behavior",
                            content:
                              section.archetype_content.online_search_behavior,
                          },
                          {
                            title: "Purchase Frequency",
                            content:
                              section.archetype_content.purchase_frequency,
                          },
                          {
                            title: "Preferred Sales Channels",
                            content:
                              section.archetype_content
                                .preferred_sales_channels,
                          },
                        ]}
                      />
                      <div className='mt-20' />
                      <SliderContainer
                        isEditing={isEditing}
                        goToIndex={goToIndex}
                        isAdding={isAdding}
                        length={section.opportunities.length}
                        selectedIndexes={selectedOpportunityIndex}
                        onActiveIndex={(index) =>
                          isAdding ? setActiveIndex(0) : setActiveIndex(index)
                        }>
                        {section.opportunities.map((item, index) => (
                          <Card
                            project={project}
                            key={index}
                            index={index}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            isFetching={isRefetching}
                            item={
                              {
                                ...item.opportunities_content,
                                id: item.id,
                                is_selected: item.is_selected === 1,
                              } as OpportunityAmongCompetitionProps
                            }
                            totalItem={section.opportunities.length}
                            onSaveEditedData={handleSaveEditedData}
                            isCanEdit={isEditor}
                            ref={(el) => (cardRefs.current[index] = el)}
                            onSuccessSelected={() =>
                              setShowAlertGenerateModal(true)
                            }
                            refreshData={refreshData}
                          />
                        ))}
                        {isAdding && (
                          <Card
                            project={project}
                            index={section.opportunities.length + 1}
                            isAdding={isAdding}
                            setIsAdding={setIsAdding}
                            item={
                              {
                                id: -1,
                                brand_persona: "",
                                features_and_benefits: "",
                                creative_communication_angle: "",
                                digital_key_touch_points: "",
                                opportunity_among_competition_number:
                                  section.opportunities.length + 1,
                                is_selected: false,
                              } as OpportunityAmongCompetitionProps
                            }
                            totalItem={section.opportunities.length}
                            onSaveEditedData={handleSaveEditedData}
                            isCanEdit={isEditor}
                            ref={(el) => (cardRefs.current[0] = el)}
                            refreshData={refreshData}
                          />
                        )}
                      </SliderContainer>
                      <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                        {isEditor &&
                          (!isAdding && !isEditing ? (
                            <button
                              className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                              onClick={toggleIsAdding}>
                              Add Idea manually
                              <Icon
                                icon='lucide:plus'
                                className='w-20 h-20'
                              />
                            </button>
                          ) : (
                            <div></div>
                          ))}
                        <div className='flex justify-end items-center gap-x-15'>
                          {isEditor &&
                            (isEditing || isAdding ? (
                              <Fragment>
                                <button
                                  disabled={updateLoading}
                                  className={`inline-flex items-center gap-x-4 cursor-pointer ${
                                    updateLoading
                                      ? "text-gray-redx"
                                      : "text-red-500"
                                  }`}
                                  onClick={
                                    isAdding ? toggleIsAdding : handleCancelEdit
                                  }>
                                  <span className='text-15 font-semibold'>
                                    Cancel
                                  </span>
                                  <Icon
                                    icon='lucide:x'
                                    className='w-20 h-20'
                                  />
                                </button>
                                <button
                                  disabled={updateLoading}
                                  className={`inline-flex items-center gap-x-4 cursor-pointer ${
                                    updateLoading
                                      ? "text-gray-redx"
                                      : "text-blue-redx"
                                  }`}
                                  onClick={handleSubmit}>
                                  <span className='text-15 font-semibold'>
                                    Save
                                  </span>
                                  <Icon
                                    icon='lucide:save'
                                    className='w-20 h-20'
                                  />
                                </button>
                              </Fragment>
                            ) : (
                              <EditButton toggleEditing={toggleIsEditing} />
                            ))}
                        </div>
                      </div>
                      <div className='flex w-full pb-60'>
                        {sources && <SourcesList sources={sources} />}
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            ) : (
              <div className='block'>
                <div className='w-full h-55 animate-pulse bg-soft-purple-redx rounded-8' />
                <div className='mt-20' />
                <SliderContainer
                  isEditing={isEditing}
                  isAdding={isAdding}
                  length={1}
                  goToIndex={goToIndex}
                  onActiveIndex={undefined}>
                  <Card
                    project={project}
                    totalItem={0}
                    item={
                      {
                        brand_persona: "",
                        features_and_benefits: "",
                        creative_communication_angle: "",
                        digital_key_touch_points: "",
                        is_selected: false,
                      } as OpportunityAmongCompetitionProps
                    }
                    isFetching={true}
                    index={-1}
                    isEditing={isAdding}
                    ref={(el) => (cardRefs.current[-1] = el)}
                  />
                </SliderContainer>
                <div className='flex w-full justify-between items-center pt-12 mb-35 border-t-1 border-stroke-redx'>
                  {isEditor &&
                    (!isAdding && !isEditing ? (
                      <button
                        className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                        onClick={undefined}>
                        Add Opportunity Manually
                        <Icon
                          icon='lucide:plus'
                          className='w-20 h-20'
                        />
                      </button>
                    ) : (
                      <div></div>
                    ))}
                  <div className='flex justify-end items-center gap-x-15'>
                    {isEditor &&
                      (isEditing || isAdding ? (
                        <Fragment>
                          <button
                            className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>
                              Cancel
                            </span>
                            <Icon
                              icon='lucide:x'
                              className='w-20 h-20'
                            />
                          </button>
                          <button
                            disabled={false}
                            className={`inline-flex items-center gap-x-4 ${
                              false ? "text-gray-600" : "text-blue-redx"
                            } cursor-pointer`}
                            onClick={undefined}>
                            <span className='text-15 font-semibold'>Save</span>
                            <Icon
                              icon='lucide:save'
                              className='w-20 h-20'
                            />
                          </button>
                        </Fragment>
                      ) : (
                        <EditButton toggleEditing={toggleIsEditing} />
                      ))}
                  </div>
                </div>
                <div className='flex w-full pb-60'>
                  <div className='inline-flex gap-10'>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </TabGroup>
        </div>
      )}

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerateNextProcess}
        onClose={() => setShowRegenerateModal(false)}
      />
    </Fragment>
  );
};

export default Index;
