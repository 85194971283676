import {
  ProjectProps,
  SubmissionProps,
  LoadingStatus,
  SubsectionDataType,
} from "@/Types/projects";
import { RouteOption } from "../reducer/projectReducer";
import { ProjectActionsTypes } from "./projectActionsType";

export interface SaveProjectDataAction {
  type: ProjectActionsTypes.SET_PROJECT_DATA;
  payload: ProjectProps;
}

export interface SaveSubmissionAction {
  type: ProjectActionsTypes.SET_SUBMISSION_DATA;
  payload: SubmissionProps;
}

export interface SaveRouteNextAction {
  type: ProjectActionsTypes.SET_ROUTE_NEXT;
  payload: RouteOption;
}
export interface SetEnableHistoryStatus {
  type: ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS;
  payload: boolean;
}

export interface SaveRoutePrevAction {
  type: ProjectActionsTypes.SET_ROUTE_PREV;
  payload: RouteOption;
}

export interface SaveInformationAction {
  type: ProjectActionsTypes.SET_INFORMATION;
  payload: string;
}
export interface SaveSubInformationAction {
  type: ProjectActionsTypes.SET_SUB_INFORMATION;
  payload: string;
}

export interface SaveLoadingAction {
  type:
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_LOADING
    | ProjectActionsTypes.SET_MARKET_RESEARCH_LOADING
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION_LOADING
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_MAIN_FEATURES_LOADING
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS_LOADING
    | ProjectActionsTypes.SET_MARKET_RESEARCH_TRENDS_LOADING
    | ProjectActionsTypes.SET_MARKET_RESEARCH_DEMAND_LOADING
    | ProjectActionsTypes.SET_AUDIENCE_ARCHETYPE_LOADING
    | ProjectActionsTypes.SET_CHALLENGE_AND_TASK_LOADING
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS_LOADING
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS_LOADING
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS_LOADING
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS_LOADING
    | ProjectActionsTypes.SET_ONE_PAGE_STRATEGY_LOADING
    | ProjectActionsTypes.SET_KEY_INSIGHT_LOADING
    | ProjectActionsTypes.SET_COMPETITOR_ANALYSIS_LOADING
    | ProjectActionsTypes.SET_OPPORTUNITY_AMONG_COMPETITION_LOADING
    | ProjectActionsTypes.SET_IDEA_ALIGNMENT_LOADING
    | ProjectActionsTypes.SET_KEY_TOUCH_POINT_LOADING;
  payload: LoadingStatus;
}

export interface SaveGeneratedDataAction {
  type:
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_MAIN_FEATURES
    | ProjectActionsTypes.SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS
    | ProjectActionsTypes.SET_MARKET_RESEARCH_TRENDS
    | ProjectActionsTypes.SET_MARKET_RESEARCH_DEMAND
    | ProjectActionsTypes.SET_AUDIENCE_ARCHETYPE_DATA
    | ProjectActionsTypes.SET_CHALLENGE_AND_TASK_DATA
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS
    | ProjectActionsTypes.SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS
    | ProjectActionsTypes.SET_ONE_PAGE_STRATEGY_DATA
    | ProjectActionsTypes.SET_KEY_INSIGHT_DATA
    | ProjectActionsTypes.SET_COMPETITOR_ANALYSIS_DATA
    | ProjectActionsTypes.SET_OPPORTUNITY_AMONG_COMPETITION_DATA
    | ProjectActionsTypes.SET_KEY_TOUCH_POINT_DATA
    | ProjectActionsTypes.SET_IDEA_ALIGNMENT_DATA;
  payload: SubsectionDataType;
}

export interface SaveProgressAction {
  type: ProjectActionsTypes.SET_PROGRESS;
  payload: number;
}

export interface SetEnableHistoryStatus {
  type: ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS;
  payload: boolean;
}

export type ProjectAction =
  | SaveProjectDataAction
  | SaveSubmissionAction
  | SaveRouteNextAction
  | SaveRoutePrevAction
  | SaveInformationAction
  | SaveSubInformationAction
  | SaveLoadingAction
  | SaveProgressAction
  | SaveGeneratedDataAction
  | SetEnableHistoryStatus
  | SetEnableHistoryStatus;

export const saveProjectData = (
  projectData: ProjectProps,
): SaveProjectDataAction => {
  return {
    type: ProjectActionsTypes.SET_PROJECT_DATA,
    payload: projectData,
  };
};

export const saveSubmissionData = (
  submission: SubmissionProps,
): SaveSubmissionAction => {
  return {
    type: ProjectActionsTypes.SET_SUBMISSION_DATA,
    payload: submission,
  };
};

export const setEnableHistoryStatus = (
  isEnabled: boolean,
): SetEnableHistoryStatus => {
  return {
    type: ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS,
    payload: isEnabled,
  };
};

export const saveRouteNext = (routeNext: RouteOption): SaveRouteNextAction => {
  return {
    type: ProjectActionsTypes.SET_ROUTE_NEXT,
    payload: routeNext,
  };
};

export const saveRoutePrev = (routePrev: RouteOption): SaveRoutePrevAction => {
  return {
    type: ProjectActionsTypes.SET_ROUTE_PREV,
    payload: routePrev,
  };
};

export const saveInformation = (information: string): SaveInformationAction => {
  return {
    type: ProjectActionsTypes.SET_INFORMATION,
    payload: information,
  };
};
export const saveSubInformation = (
  subInformation: string,
): SaveSubInformationAction => {
  return {
    type: ProjectActionsTypes.SET_SUB_INFORMATION,
    payload: subInformation,
  };
};

export const setProductResearchLoading = (
  isProductResearchLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_PRODUCT_RESEARCH_LOADING,
    payload: isProductResearchLoading,
  };
};

export const setMarketResearchLoading = (
  isMarketResearchLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_MARKET_RESEARCH_LOADING,
    payload: isMarketResearchLoading,
  };
};

export const setMarketResearchTrends = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_MARKET_RESEARCH_TRENDS,
    payload: data,
  };
};

export const setMarketResearchTrendsLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_MARKET_RESEARCH_TRENDS_LOADING,
    payload: isLoading,
  };
};

export const setMarketResearchDemand = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_MARKET_RESEARCH_DEMAND,
    payload: data,
  };
};

export const setMarketResearchDemandLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_MARKET_RESEARCH_DEMAND_LOADING,
    payload: isLoading,
  };
};

export const setChallengeAndTaskData = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_CHALLENGE_AND_TASK_DATA,
    payload: data,
  };
};

export const setChallengeAndTaskLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_CHALLENGE_AND_TASK_LOADING,
    payload: isLoading,
  };
};

export { ProjectActionsTypes };
