import React, { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import AvatarLabel from "@/Components/AvatarLabel";
import ButtonCopyLink from "@/Components/ButtonCopyLink";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import ModalCenter from "@/Components/Modals/Center";
import PopoverRolesInput from "@/Components/PopoverRolesInput";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import TextInputTags from "@/Components/TextInputTags";
import { User } from "@/Types";
import { getRandomString } from "@/Utils/helper";
import { Icon } from "@iconify/react/dist/iconify.js";
import RequestAccessModal from "./RequestAccessModal";
import UnsavedChangesPopup from "./UnsavedChangesPopup";
import { useNavigate } from "react-router-dom";
import { fetcher } from "@/Services/axios";
import RequestAccessSuccessToast from "@/Components/Toast/RequestAccessSuccess";

interface UserPayload {
  id: number;
  email: string;
  roles: string[];
  error?: string;
}

interface MainForm {
  project_name: string;
  product_name: string;
  is_released: boolean;
  members: UserPayload[];
  slug: string;
  external_members: ExternalUser[];
  emails: string[];
  roles: string[];
}

interface ExternalUser {
  email: string;
  roles: string[];
}

const CreateFormModal: React.FC<{
  isOpen: boolean;
  initialUsers: User[];
  onClose: () => void;
  auth: { user: User };
  appUrl: string;
}> = ({ isOpen, initialUsers, onClose, auth, appUrl }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues: getCheckValues,
    watch,
    formState: { errors },
  } = useForm<MainForm>({
    defaultValues: {
      project_name: "",
      product_name: "",
      is_released: false,
      members: [],
      slug: getRandomString(8),
      external_members: [],
    },
  });
  const [childCount] = useState<number>(2);
  const [validatedUsers, setValidatedUsers] = useState<User[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isClearTags, setIsClearTags] = useState<boolean>(false);
  const [isClearRoles, setIsClearRoles] = useState<boolean>(false);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [isOpenModalRequestAccess, setIsOpenModalRequestAccess] =
    useState<boolean>(false);
  const [requestAccessRoles, setRequestAccessRoles] = useState<string[]>([]);
  const [validatedExternalUsers, setValidatedExternalUsers] = useState<
    ExternalUser[]
  >([]);
  const [excludedUsers, setExcludedUsers] = useState<User[]>([auth.user]);
  const [popUnsave, setPopupUnsave] = useState<boolean>(false);
  const navigate = useNavigate();

  const handlePopup = (state: boolean) => {
    if (currentStep == 0) {
      handleCloseModal();
    } else {
      setPopupUnsave(state);
    }
  };

  const handleCloseModal = () => {
    onClose();
    closeModalRequestAccess();
    handlePopup(false);
  };

  const closeModalRequestAccess = () => setIsOpenModalRequestAccess(false);

  const onSubmit: SubmitHandler<MainForm> = async () => {
    handlePopup(false);
    const data = {
      ...getCheckValues(),
      is_released: Boolean(getCheckValues().is_released),
      members: validatedUsers.map((user) => ({
        id: user.id,
        email: user.email,
        roles: user.roles,
      })),
      external_members: validatedExternalUsers,
    };
    try {
      setIsSubmitting(true);
      await fetcher.post("/projects", data);
      navigate("/" + watch("slug") + "/submission");
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    if (currentStep < childCount - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const removeUser = useCallback(
    (user: User) => {
      setValidatedUsers(validatedUsers.filter((u) => u.email !== user.email));
      setValue(
        "members",
        watch("members").filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedUsers, excludedUsers, setValue, watch],
  );

  const handleAddUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const response = await fetcher.post(`/projects/validate-teammates`, {
        emails: getCheckValues("emails"),
      });
      const { invalidEmails: invalidEmailsData, validatedEmails } =
        response.data;
      if (invalidEmailsData.length > 0) {
        setInvalidEmails(invalidEmailsData);
        setIsOpenModalRequestAccess(true);
      }
      if (validatedEmails.length > 0) {
        const users = validatedEmails.map((email: string) => {
          const user = initialUsers.find((user) => user.email === email);
          return { ...user, roles: watch("roles") } as User;
        });
        setValidatedUsers([...validatedUsers, ...users]);
        setExcludedUsers([...excludedUsers, ...users]);
        setValue("members", [
          ...watch("members"),
          ...users.map((user: User) => ({
            id: user.id,
            email: user.email,
            roles: user.roles,
          })),
        ]);
        handleClearTags();
        handleClearRoles();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearTags = () => {
    setIsClearTags(true);
    setValue("emails", []);
  };

  const handleClearTagsComplete = () => {
    setIsClearTags(false);
  };

  const handleClearRoles = () => {
    setIsClearRoles(true);
  };

  const handleClearRolesComplete = () => {
    setIsClearRoles(false);
  };

  const handleRequestAccess = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    closeModalRequestAccess();
    const externalUsers = invalidEmails.map((email) => ({
      email,
      roles: requestAccessRoles,
    }));
    setValidatedExternalUsers([...validatedExternalUsers, ...externalUsers]);
    setValue("external_members", [
      ...watch("external_members"),
      ...externalUsers,
    ]);
    setExcludedUsers([
      ...excludedUsers,
      ...externalUsers.map((user) => ({
        id: 0,
        email: user.email,
        avatar: "",
        avatar_url: "",
        email_verified_at: "",
        created_at: "",
        updated_at: "",
        name: "",
        roles: user.roles,
        whatsapp_number: "",
        status: "",
      })),
    ]);
  };

  const removeExternalUser = useCallback(
    (user: ExternalUser) => {
      setValidatedExternalUsers(
        validatedExternalUsers.filter((u) => u.email !== user.email),
      );
      setValue(
        "external_members",
        watch("external_members").filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedExternalUsers, excludedUsers, setValue, watch],
  );

  const handleCloseModalRequestAccess = () => {
    setIsOpenModalRequestAccess(false);
    setInvalidEmails([]);
  };

  return (
    <React.Fragment>
      <ModalCenter
        isOpen={isOpen}
        onClose={() => handlePopup(true)}>
        <div
          className={`relative w-full ${
            currentStep == 0 ? "max-w-md max-h-400" : "max-w-4xl"
          }`}>
          <div
            className='flex transition-transform transform duration-500 z-10'
            style={{ transform: `translateX(-${currentStep * 100}%)` }}>
            <div className='w-full flex-shrink-0'>
              <div className='rounded-10 relative py-50 px-40 md:py-50 md:px-30'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <h1 className='font-medium text-25 text-black-redx leading-30 mb-10'>
                      Let’s start your project!
                    </h1>
                  </div>
                  <div className='mb-20'>
                    <InputLabel
                      htmlFor='project_name'
                      value='Project Name'
                      className='!text-black-redx'
                    />
                    <Controller
                      name='project_name'
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id='project_name'
                          type='text'
                          className='mt-1 block w-full'
                          containerClassName='focus-within:!border-b-2 focus-within:border-blue-purple-redx'
                          placeholder='Input your project name here'
                        />
                      )}
                    />
                    {errors.project_name && (
                      <InputError
                        message={errors.project_name.message}
                        light={true}
                        className='mt-2'
                      />
                    )}
                  </div>
                  <div className='mb-20'>
                    <InputLabel
                      htmlFor='product_name'
                      value='Product Name'
                      className='!text-black-redx'
                    />
                    <Controller
                      name='product_name'
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          {...field}
                          id='product_name'
                          type='text'
                          className='mt-1 block w-full'
                          containerClassName='focus-within:!border-b-2 focus-within:border-blue-purple-redx'
                          placeholder='Input your product name here'
                        />
                      )}
                    />
                    {errors.product_name && (
                      <InputError
                        message={errors.product_name.message}
                        light={true}
                        className='mt-2'
                      />
                    )}
                  </div>
                  <div>
                    <InputLabel
                      htmlFor='is_released'
                      value='Has your product been released?'
                      className='!text-black-redx'
                    />
                    <ul className='flex items-center text-sm font-medium bg-transparent rounded-md mt-1 w-full border-transparent py-8'>
                      <li className='w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600'>
                        <div className='flex items-center ps-3'>
                          <input
                            {...register("is_released")}
                            id='yes'
                            type='radio'
                            value='true'
                            className='w-18 h-18 text-blue-purple-redx bg-gray-100 border-gray-200 border-1 !focus:blue-purple-redx !focus:ring-2'
                            defaultChecked={true}
                          />
                          <label
                            htmlFor='yes'
                            className='w-full py-3 ms-10 text-14 font-medium text-black-redx'>
                            {" "}
                            Yes{" "}
                          </label>
                        </div>
                      </li>
                      <li className='w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600'>
                        <div className='flex items-center ps-3'>
                          <input
                            {...register("is_released")}
                            id='no'
                            type='radio'
                            value='false'
                            className='w-18 h-18 text-blue-purple-redx bg-gray-100 border-gray-200 border-1 !focus:blue-purple-redx !focus:ring-2'
                          />
                          <label
                            htmlFor='no'
                            className='w-full py-3 ms-10 text-14 font-medium text-black-redx'>
                            {" "}
                            No{" "}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </form>
                <div className='flex justify-end items-center mt-30 gap-x-10'>
                  <PrimaryButton
                    className='text-12 ms-4 px-20 py-10 bg-transparent border-1 border-blue-redx !text-blue-redx'
                    onClick={onClose}>
                    {" "}
                    Close{" "}
                  </PrimaryButton>
                  <PrimaryButton
                    className='text-12 ms-4 px-20 py-10 border-1 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx'
                    disabled={!watch("project_name") || !watch("product_name")}
                    onClick={nextStep}>
                    {" "}
                    Next{" "}
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className='relative w-full rounded-10 flex-shrink-0 p-[36px] md:py-50 md:px-30 max-h-[80vh] overflow-y-auto'>
              {popUnsave && (
                <UnsavedChangesPopup
                  onBack={() => handlePopup(false)}
                  onClose={handleCloseModal}
                />
              )}
              <div className='flex gap-20 items-center justify-items-center'>
                <Icon
                  onClick={previousStep}
                  icon='ep:back'
                  className='w-15 h-15 text-black-redx hover:cursor-pointer shrink-0'
                />
                <h1 className='font-medium text-25 text-black leading-30'>
                  Add your teammates!
                </h1>
              </div>
              <div className='flex flex-col gap-[36px]'>
                <div className='flex items-start gap-24 mt-24'>
                  <div className='flex-1'>
                    <div className='flex items-start md:flex-col justify-between gap-24'>
                      <div className='flex-1 md:w-full'>
                        <InputLabel
                          htmlFor='emails'
                          value='Email'
                          className='!text-black-redx mb-4'
                        />
                        <TextInputTags
                          id='emails'
                          placeholder='Input your teammates email here'
                          containerClassName='text-black-redx border-b-2 border-soft-purplestroke-redx'
                          className='px-0 !flex-grow'
                          onChangeTags={(tags) => setValue("emails", tags)}
                          initialTags={[]}
                          initialUsers={initialUsers}
                          exludedUsers={excludedUsers}
                          isClearTags={isClearTags}
                          onClearTagsComplete={handleClearTagsComplete}
                        />
                      </div>
                      <div className='w-7/24 md:w-full'>
                        <InputLabel
                          className='!text-black-redx'
                          htmlFor='roles'
                          value='Roles'
                        />
                        <PopoverRolesInput
                          containerClassName='w-full !ps-0 text-black-redx border-b-2 border-soft-purplestroke-redx'
                          initialRoles={["Strategist", "Creative", "Content"]}
                          onChangeRoles={(roles) => {
                            setValue("roles", roles);
                            if (roles.length > 0) {
                              setRequestAccessRoles(roles);
                            }
                          }}
                          isClearRoles={isClearRoles}
                          onClearRolesComplete={handleClearRolesComplete}
                        />
                      </div>
                    </div>
                  </div>
                  <PrimaryButton
                    onClick={(e) => handleAddUser(e)}
                    className='!text-16 !leading-18 !font-semibold !px-22 !py-14 w-100 shrink-0 bg-transparent border-1 border-blue-redx !text-blue-redx'
                    disabled={
                      watch("emails")?.length === 0 ||
                      watch("roles")?.length === 0
                    }>
                    Add
                  </PrimaryButton>
                </div>
                <div className='flex flex-col gap-12'>
                  <InputLabel
                    className='!text-black-redx'
                    htmlFor='members'
                    value='Members'
                  />
                  <div
                    className={
                      currentStep != 0 ? "overflow-y-auto max-h-5/6" : ""
                    }>
                    <div className='flex items-center gap-24 py-5'>
                      <div className='flex-1'>
                        <div className='flex items-center justify-between gap-20'>
                          <div className='flex-1'>
                            <AvatarLabel
                              src={auth.user.avatar_url}
                              title={auth.user.name}
                              subtitle={auth.user.email}
                            />
                          </div>
                          <div className='w-8/24'>
                            <p className='text-black-redx/50 ps-8 font-semibold text-14 leading-20'>
                              Owner
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='w-100'>
                        <div className='flex items-center justify-center'>
                          <Icon
                            icon='uil:trash'
                            className='w-24 h-24 text-black-redx/50'
                          />
                        </div>
                      </div>
                    </div>
                    {validatedUsers.map((user, i) => (
                      <div
                        key={user.email}
                        className='flex items-center gap-24 py-5'>
                        <div className='flex-1'>
                          <div className='flex items-center justify-between gap-20'>
                            <div className='flex-1'>
                              <AvatarLabel
                                src={user.avatar_url}
                                title={user.name}
                                subtitle={user.email}
                              />
                            </div>
                            <div className='relative w-8/24'>
                              <PopoverRolesInput
                                containerClassName='w-full'
                                className='font-semibold'
                                initialRoles={[
                                  "Strategist",
                                  "Creative",
                                  "Content",
                                ]}
                                selectedRoles={user.roles}
                                onChangeRoles={(roles) => {
                                  setValue(
                                    "members",
                                    watch("members").map((m) =>
                                      m.email === user.email
                                        ? { ...m, roles }
                                        : m,
                                    ),
                                  );
                                  setValidatedUsers(
                                    validatedUsers.map((u) =>
                                      u.email === user.email
                                        ? { ...u, roles }
                                        : u,
                                    ),
                                  );
                                }}
                                error={
                                  errors.members &&
                                  errors.members[i] &&
                                  (errors.members[i].roles as any)
                                }
                              />
                              {errors.members && errors.members[i] && (
                                <InputError
                                  message={
                                    (errors.members[i].roles as any)?.message
                                  }
                                  light={false}
                                  className=''
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='w-100'>
                          <div className='flex items-center justify-center'>
                            <Icon
                              onClick={() => removeUser(user)}
                              icon='uil:trash'
                              className='w-24 h-24 text-black-redx cursor-pointer'
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {validatedExternalUsers &&
                      validatedExternalUsers.length > 0 && (
                        <div className='py-10'>
                          <InputLabel
                            className='!text-black-redx'
                            htmlFor='nonmembers'
                            value='Requested Members'
                          />
                        </div>
                      )}
                    {validatedExternalUsers &&
                      validatedExternalUsers.map((user) => (
                        <div
                          key={user.email}
                          className='flex items-center justify-between gap-20 py-8'>
                          <div className='flex-1'>
                            <div className='flex items-center gap-10'>
                              <Icon
                                icon='mdi:user-circle'
                                className='w-32 h-32'
                              />
                              <div className='font-medium text-black'>
                                <div className='text-14 font-semibold leading-16 text-black-redx mb-5'>
                                  {user.email}
                                </div>
                                <div className='text-12 text-grey-redx leading-14'>
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='w-6/24'>
                            <p className='text-14'>{user.roles.join(", ")}</p>
                          </div>
                          <div className='w-100'>
                            <div className='flex items-center justify-center'>
                              <Icon
                                onClick={() => removeExternalUser(user)}
                                icon='uil:trash'
                                className='w-24 h-24 text-black-redx cursor-pointer'
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className='flex items-end justify-between py-10 border-b-2 border-soft-purple-redx'>
                  <div className='text-black-redx'>
                    <p className='text-12 leading-16 font-semibold'>
                      Share Project Link
                    </p>
                    <p className='text-14 font-normal leading-18'>
                      {appUrl}/{watch("slug")}
                    </p>
                  </div>
                  <ButtonCopyLink value={`${appUrl}/${watch("slug")}`} />
                </div>
                <div className='flex justify-end items-center mt-30 gap-x-10'>
                  <PrimaryButton
                    className={`!text-16 !leading-18 ms-4 px-31 py-16 ${
                      isSubmitting
                        ? "bg-placeholder-redx border-placeholder-redx cursor-not-allowed text-white "
                        : "bg-blue-redx hover:bg-blue-redx active:bg-blue-redx cursor-pointer"
                    }`}
                    disabled={isSubmitting}
                    onClick={handleSubmit(onSubmit)}>
                    Create Project
                  </PrimaryButton>
                </div>
              </div>
              <RequestAccessModal
                roles={requestAccessRoles}
                emails={invalidEmails}
                isOpen={isOpenModalRequestAccess}
                onClose={handleCloseModalRequestAccess}
                onClickRequest={(e) => handleRequestAccess(e)}
              />
            </div>
          </div>
        </div>
      </ModalCenter>
    </React.Fragment>
  );
};

export default CreateFormModal;
