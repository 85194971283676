import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { CreativeIdeaResponse } from "@/Types/creative-idea/creative-idea";

const fetch = async (projectSlug: string): Promise<CreativeIdeaResponse> => {
  try {
    const response = await fetcher.get(`/${projectSlug}/idea-submission`);
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch submisison data",
    );
  }
};

const useIdeaCreative = (projectSlug: string) => {
  return useQuery<CreativeIdeaResponse, Error>({
    queryKey: ["ideaCreative", projectSlug],
    queryFn: () => fetch(projectSlug),
    refetchOnWindowFocus: false,
  });
};

export { useIdeaCreative };
