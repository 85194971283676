import {
  ProductResearchProps,
  MarketResearchProps,
  ProjectProps,
  SubmissionProps,
  LoadingStatus,
  SubsectionProps,
} from "@/Types/projects";
import { ProjectAction } from "../actions/projectActions";
import { ProjectActionsTypes } from "../actions/projectActionsType";
import { TargetAudienceProps } from "@/Types/target_audience";
import { Archetype } from "@/Types/audience-archetype";

export interface RouteOption {
  label: string;
  isDisabled?: boolean;
  isGenerate?: boolean;
  isActive: boolean;
  isInactive?: boolean;
  onClick: () => void;
}

export interface State {
  projectData: ProjectProps;
  submission: SubmissionProps;
  routeNext: RouteOption;
  routePrev: RouteOption;
  information: string;
  subInformation: string;
  flagRefetchDifferent: string;
  productResearch: ProductResearchProps;
  marketResearch: MarketResearchProps;
  audienceArchetype: SubsectionProps;
  challengeAndTask: SubsectionProps;
  targetAudience: TargetAudienceProps;
  keyInsight: SubsectionProps;
  competitorAnalysis: SubsectionProps;
  opportunityAmongCompetition: SubsectionProps;
  onePageStrategy: SubsectionProps;
  keyTouchPoint: SubsectionProps;
  isProductResearchLoading: LoadingStatus;
  isMarketResearchLoading: LoadingStatus;
  isEnabledHistoryStatus: boolean;
  isProductResearchProductDescriptionLoading: LoadingStatus;
}

const defaultPageState: SubsectionProps = {
  status: false,
  progress: 0,
  isQuerying: false,
  data: {
    history_id: 0,
    content: "",
    created_at: "",
    updated_at: "",
    selectedContent: [],
    sources: [],
    total_regenerate: 0,
  },
};

export const initialState: State = {
  projectData: {} as ProjectProps,
  submission: {} as SubmissionProps,
  routeNext: {
    label: "Next",
    isActive: true,
    isGenerate: false,
    isDisabled: false,
    isInactive: false,
    onClick: () => {},
  },
  routePrev: {
    label: "Back",
    isActive: false,
    isGenerate: false,
    isDisabled: false,
    isInactive: false,
    onClick: () => {},
  },
  isEnabledHistoryStatus: true,
  information: "",
  subInformation: "",
  flagRefetchDifferent: "",
  productResearch: {
    product_description: defaultPageState,
    main_features: defaultPageState,
    marcomm_highlights: defaultPageState,
  },
  marketResearch: {
    trends: defaultPageState,
    demand: defaultPageState,
  },
  audienceArchetype: defaultPageState,
  challengeAndTask: defaultPageState,
  targetAudience: {
    audience_insights: defaultPageState,
    behavioral_trends: defaultPageState,
    media_consumption_patterns: defaultPageState,
    perception_analysis: defaultPageState,
  },
  onePageStrategy: defaultPageState,
  isProductResearchLoading: {
    status: false,
    progress: 0,
  },
  isMarketResearchLoading: {
    status: false,
    progress: 0,
  },
  isProductResearchProductDescriptionLoading: {
    status: false,
    progress: 0,
  },
  keyInsight: defaultPageState,
  competitorAnalysis: defaultPageState,
  opportunityAmongCompetition: defaultPageState,
  keyTouchPoint: defaultPageState,
};

const projectReducer = (state: State, action: ProjectAction) => {
  switch (action.type) {
    case ProjectActionsTypes.SET_PROJECT_DATA:
      return {
        ...state,
        projectData: action.payload,
      };
    case ProjectActionsTypes.SET_ENABLED_HISTORY_STATUS:
      return {
        ...state,
        isEnabledHistoryStatus: action.payload,
      };
    case ProjectActionsTypes.SET_SUBMISSION_DATA:
      return {
        ...state,
        submission: action.payload,
      };
    case ProjectActionsTypes.SET_ROUTE_NEXT:
      return {
        ...state,
        routeNext: action.payload,
      };
    case ProjectActionsTypes.SET_ROUTE_PREV:
      return {
        ...state,
        routePrev: action.payload,
      };

    case ProjectActionsTypes.SET_INFORMATION:
      return {
        ...state,
        information: action.payload,
      };
    case ProjectActionsTypes.SET_SUB_INFORMATION:
      return {
        ...state,
        subInformation: action.payload,
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_LOADING:
      return {
        ...state,
        isProductResearchLoading: action.payload,
      };
    case ProjectActionsTypes.SET_MARKET_RESEARCH_LOADING:
      return {
        ...state,
        isMarketResearchLoading: action.payload,
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION:
      return {
        ...state,
        productResearch: {
          ...state.productResearch,
          product_description: {
            ...state.productResearch.product_description,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_PRODUCT_DESCRIPTION_LOADING:
      return {
        ...state,
        productResearch: {
          ...state.productResearch,
          product_description: {
            ...state.productResearch.product_description,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_MAIN_FEATURES:
      return {
        ...state,
        productResearch: {
          ...state.productResearch,
          main_features: {
            ...state.productResearch.main_features,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_MAIN_FEATURES_LOADING:
      return {
        ...state,
        productResearch: {
          ...state.productResearch,
          main_features: {
            ...state.productResearch.main_features,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS:
      return {
        ...state,
        productResearch: {
          ...state.productResearch,
          marcomm_highlights: {
            ...state.productResearch.marcomm_highlights,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_PRODUCT_RESEARCH_MARCOMM_HIGHLIGHTS_LOADING:
      return {
        ...state,
        productResearch: {
          ...state.productResearch,
          marcomm_highlights: {
            ...state.productResearch.marcomm_highlights,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_MARKET_RESEARCH_TRENDS:
      return {
        ...state,
        marketResearch: {
          ...state.marketResearch,
          trends: {
            ...state.marketResearch.trends,
            data: action.payload,
          },
        },
      };

    // target audience
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          audience_insights: {
            ...state.targetAudience.audience_insights,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_MARKET_RESEARCH_TRENDS_LOADING:
      return {
        ...state,
        marketResearch: {
          ...state.marketResearch,
          trends: {
            ...state.marketResearch.trends,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS_LOADING:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          audience_insights: {
            ...state.targetAudience.audience_insights,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_MARKET_RESEARCH_DEMAND:
      return {
        ...state,
        marketResearch: {
          ...state.marketResearch,
          demand: {
            ...state.marketResearch.demand,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          perception_analysis: {
            ...state.targetAudience.perception_analysis,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_MARKET_RESEARCH_DEMAND_LOADING:
      return {
        ...state,
        marketResearch: {
          ...state.marketResearch,
          demand: {
            ...state.marketResearch.demand,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS_LOADING:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          perception_analysis: {
            ...state.targetAudience.perception_analysis,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_AUDIENCE_ARCHETYPE_DATA:
      return {
        ...state,
        audienceArchetype: {
          ...state.audienceArchetype,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_AUDIENCE_ARCHETYPE_LOADING:
      return {
        ...state,
        audienceArchetype: {
          ...state.audienceArchetype,
          ...action.payload,
        },
      };

    case ProjectActionsTypes.SET_CHALLENGE_AND_TASK_DATA:
      return {
        ...state,
        challengeAndTask: {
          ...state.challengeAndTask,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_CHALLENGE_AND_TASK_LOADING:
      return {
        ...state,
        challengeAndTask: {
          ...state.challengeAndTask,
          ...action.payload,
        },
      };

    case ProjectActionsTypes.SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          behavioral_trends: {
            ...state.targetAudience.behavioral_trends,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS_LOADING:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          behavioral_trends: {
            ...state.targetAudience.behavioral_trends,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          media_consumption_patterns: {
            ...state.targetAudience.media_consumption_patterns,
            data: action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS_LOADING:
      return {
        ...state,
        targetAudience: {
          ...state.targetAudience,
          media_consumption_patterns: {
            ...state.targetAudience.media_consumption_patterns,
            ...action.payload,
          },
        },
      };
    case ProjectActionsTypes.SET_ONE_PAGE_STRATEGY_DATA:
      return {
        ...state,
        onePageStrategy: {
          ...state.onePageStrategy,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_ONE_PAGE_STRATEGY_LOADING:
      return {
        ...state,
        onePageStrategy: {
          ...state.onePageStrategy,
          ...action.payload,
        },
      };
    case ProjectActionsTypes.SET_KEY_INSIGHT_DATA:
      return {
        ...state,
        keyInsight: {
          ...state.keyInsight,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_KEY_INSIGHT_LOADING:
      return {
        ...state,
        keyInsight: {
          ...state.keyInsight,
          ...action.payload,
        },
      };

    case ProjectActionsTypes.SET_COMPETITOR_ANALYSIS_DATA:
      return {
        ...state,
        competitorAnalysis: {
          ...state.competitorAnalysis,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_COMPETITOR_ANALYSIS_LOADING:
      return {
        ...state,
        competitorAnalysis: {
          ...state.competitorAnalysis,
          ...action.payload,
        },
      };
    case ProjectActionsTypes.SET_OPPORTUNITY_AMONG_COMPETITION_DATA:
      return {
        ...state,
        opportunityAmongCompetition: {
          ...state.opportunityAmongCompetition,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_OPPORTUNITY_AMONG_COMPETITION_LOADING:
      return {
        ...state,
        opportunityAmongCompetition: {
          ...state.opportunityAmongCompetition,
          ...action.payload,
        },
      };
    case ProjectActionsTypes.SET_KEY_TOUCH_POINT_DATA:
      return {
        ...state,
        keyTouchPoint: {
          ...state.keyTouchPoint,
          data: action.payload,
        },
      };
    case ProjectActionsTypes.SET_KEY_TOUCH_POINT_LOADING:
      return {
        ...state,
        keyTouchPoint: {
          ...state.keyTouchPoint,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default projectReducer;
