import React, { useContext } from "react";

import { ProjectProps } from "@/Types/projects";
import { mockUserData } from "@/Mocks/usersMocks";
import { ProjectContext } from "@/Context/ProjectContext";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { formatInformation } from "@/Utils/helper";
import PencilGenerate from "../Icon/pencil-generate";

const ProjectFooter: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const [state] = useContext(ProjectContext);
  const user = mockUserData;
  const { roles } = useRolesBadge(project, user);
  const isViewOnly = roles.includes("View Only");

  const formattedInformation = formatInformation(state.information);
  const formattedSubInformation = formatInformation(state.subInformation ?? "");

  const buttonNextGradient = state.routeNext.isGenerate
    ? "bg-button-gradient-90"
    : "bg-blue-redx border-blue-redx";

  const buttonNextDisabledClass =
    state.routeNext.isDisabled || state.routeNext.isInactive
      ? `bg-placeholder-redx border-placeholder-redx ${
          state.routeNext.isDisabled && "cursor-not-allowed"
        } text-white`
      : `${buttonNextGradient} cursor-pointer`;

  return (
    !isViewOnly && (
      <div
        className={
          "fixed bottom-0 z-20 pl-20 pr-30 py-10 shadow-lg bg-white right-0 left-0 lg:pl-[26%]"
        }
        style={{
          boxShadow: "0px -4px 20px 0px #0000001F",
        }}>
        <div className='flex w-full justify-between items-center flex-col lg:flex-row gap-8'>
          <div className='w-full flex-1'>
            <p className={formattedInformation.className}>
              {formattedInformation.content}
            </p>
            <p className={formattedSubInformation.className}>
              {formattedSubInformation.content}
            </p>
          </div>
          <div className='flex gap-x-24 flex-row md:flex-col gap-10 w-full flex-1 justify-end'>
            {state.routePrev.isActive && (
              <button
                className='text-20 px-24 py-12 border-blue-redx text-blue-redx font-semibold border-2 rounded-lg bg-transparent cursor-pointer'
                onClick={state.routePrev.onClick}>
                {state.routePrev.label}
              </button>
            )}
            {state.routeNext.isActive && (
              <button
                className={`text-20 px-24 py-12 border-2 gap-8 font-semibold rounded-lg text-white flex flex-row justify-center items-center whitespace-nowrap md:whitespace-break-spaces ${buttonNextDisabledClass}`}
                onClick={state.routeNext.onClick}
                disabled={state.routeNext.isDisabled ?? false}>
                {state.routeNext.label}
                {state.routeNext.isGenerate && <PencilGenerate />}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ProjectFooter;
