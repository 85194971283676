import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveRouteNext,
  saveRoutePrev,
  saveInformation,
  saveSubInformation,
  setEnableHistoryStatus,
} from "@/Context/actions/projectActions";
import Card from "./Partials/Card";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import EditButton from "@/Components/Projects/EditButton";
import GenerateLoading from "@/Components/GenerateLoading";
import SliderContainer from "@/Components/Projects/SliderContainer";
import SourcesList from "@/Components/Projects/SourcesList";

import { useGenerate } from "@/Context/hooks/useGenerate";
import useRolesBadge from "@/Hooks/useRolesBadge";

import { RouteOption } from "@/Context/reducer/projectReducer";

import { Archetype, SubsectionArchetypeType } from "@/Types/audience-archetype";
import { ProjectProps, SourcesType } from "@/Types/projects";
import { progressPortion } from "@/Utils/dispatcher";

import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { Schema } from "@/Types/audience-archetype/schema";
import { useUpdateArchetype } from "@/Hooks/react-query/audience-archetype";
import useUser from "@/Hooks/react-query/useUser";
import ConfirmationModal from "./Partials/ConfirmationModal";
import useArchetypes from "@/Hooks/react-query/audience-archetype/useArchetypes";
import RegenerateInfoModal from "@/Components/Modals/RegenerateInfoModal";
import { fetcher } from "@/Services/axios";
import { SectionList } from "@/Types/tabs";
import { useQueryClient } from "@tanstack/react-query";
import RegenerateModal from "@/Components/Modals/RegenerateModal";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";

export type CardHandles = {
  submitForm: () => void;
};

const AudienceArchetypeIndex: React.FC<{ project: ProjectProps }> = ({
  project,
}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [state, dispatch] = useContext(ProjectContext);
  const [isRegenerating, setIsReGenerating] = useState(false);
  const [isRefetchDiffArch, setIsRefetchDiffArch] = useState<string>("idle");
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAlertGenerateModal, setShowAlertGenerateModal] = useState(false);
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [parsedData, setParsedData] = useState<SubsectionArchetypeType[]>([]);
  const [editedData, setEditedData] = useState<SubsectionArchetypeType[]>([]);
  const [sources, setSources] = useState<SourcesType[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number[] | undefined>();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [totalRegenerate, setTotalRegenerate] = useState<number>(0);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isWaitingTargetAudience, setIsWaitingTargetAudience] = useState(false);
  const [goToIndex, setGoToIndex] = useState<number | undefined>(undefined);
  const [selectedEditedData, setSelectedEditedData] =
    useState<SubsectionArchetypeType>();
  const cardRefs = useRef<(CardHandles | null)[]>([]);
  const { mutate, isPending } = useUpdateArchetype();

  const { data: user } = useUser();

  const { roles } = useRolesBadge(project, user);
  const {
    handleGenerateAudienceArchetype,
    handleGenerateKeyInsight,
    handleGenerateOpportunityAmongCompetition,
  } = useGenerate(dispatch);
  const { data, isLoading, refetch, isRefetching } = useArchetypes(project);

  const {
    isAudienceArchetypeComplete,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isOpportunityAmongCompetitionComplete,
    isPending: isPendingHistoryStatus,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");

  const toggleIsEditing = () => setIsEditing(!isEditing);
  const toggleIsAdding = () => setIsAdding(!isAdding);
  const {
    data: differentArchetypes,
    refetch: refetchDiff,
    isRefetching: isPendingDiffArch,
  } = useDifferentArchetypes(project);

  const routeOptionValue: RouteOption = {
    label: "Generate Key Insight",
    isActive: true,
    isGenerate: true,
    isInactive: true,
    isDisabled: false,
    onClick: async () => {
      dispatch(saveSubInformation(""));
      if (state.routeNext.isDisabled) return;

      if (!selectedIndex?.length) {
        dispatch(
          saveSubInformation(
            `{text-error-redx} Please select audience archetype first`,
          ),
        );
        return;
      }

      setIsRefetchDiffArch("fetching");
      dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
      await refetchDiff();
      setIsRefetchDiffArch("done");
      dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    },
  };

  const handleCancelEdit = () => {
    setParsedData(editedData);
    toggleIsEditing();
  };

  const handleFetchSubmit = async (
    payload: Schema,
    id: number,
    index: number,
  ) => {
    const projectSlug = project.slug;

    mutate(
      { payload: payload as unknown as Archetype, id, projectSlug },
      {
        onSuccess: () => {
          if (isAdding) {
            setTimeout(() => {
              setGoToIndex(index);
            }, 1000);
          }

          isAdding && toggleIsAdding();
          isEditing && toggleIsEditing();

          setTimeout(() => {
            refetch();
          }, 700);
        },
      },
    );
  };

  const handleRefetchSelectedData = async () => {
    queryClient.resetQueries({
      queryKey: ["keyInsights", project.slug],
      exact: true,
    });

    //dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: true }));
    const { data } = await fetcher.get(
      `/${project.slug}/audience-archetype/check-regenerate`,
    );
    dispatch(saveRouteNext({ ...routeOptionValue, isDisabled: false }));
    if (data && data.data.is_show_pop_up) {
      setShowRegenerateModal(data.data.is_show_pop_up);
      return;
    }

    navigate(`/${project.slug}/key-insight`);
  };

  const handleRegenerateButton = (data: { prompt: string }) => {
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
    setParsedData([]);
    setEditedData([]);
    setIsGenerate(true);
    setSelectedIndex([]);
    handleGenerateAudienceArchetype()(project.slug, data.prompt);
  };

  const onSelectSuccess = (isShowPopUp: boolean) => {
    setShowAlertGenerateModal(isShowPopUp);
    refetch();
  };

  const handleSubmit = () => {
    cardRefs.current.forEach((ref, index) => {
      if (ref && index === activeIndex) {
        ref.submitForm();
      }
    });
  };

  const handleRegenerate = () => {
    dispatch(saveInformation(""));
    dispatch(saveSubInformation(""));
    setIsReGenerating(true);
    handleGenerateKeyInsight()(project.slug, null);
    if (isOpportunityAmongCompetitionComplete.status)
      handleGenerateOpportunityAmongCompetition()(project.slug, null);
    setIsReGenerating(false);
    navigate(`/${project.slug}/key-insight`);
  };

  useEffect(() => {
    if (
      data &&
      data.data &&
      data.data.data &&
      data.data.data.archetypes.length > 0
    ) {
      const { archetypes, sources, total_regenerate } = data.data.data;
      setParsedData(archetypes);
      setEditedData(archetypes);
      setSources(sources);
      setTotalRegenerate(total_regenerate);
    } else if (
      !isLoading &&
      !isPendingHistoryStatus &&
      isAudienceArchetypeComplete.status === false
    ) {
      setIsGenerate(true);
      if (!isAudienceArchetypeComplete.isQuerying)
        handleGenerateAudienceArchetype()(project.slug);
    }
  }, [data]);

  useEffect(() => {
    if (
      (isAudienceArchetypeComplete.status &&
        !isAudienceArchetypeComplete.isQuerying) ||
      parsedData?.length === 0
    ) {
      refetch();
    }

    if (
      isAudienceArchetypeComplete.status &&
      !isAudienceArchetypeComplete.isQuerying &&
      parsedData?.length > 0
    ) {
      setIsGenerate(false);
    }

    if (isAudienceArchetypeComplete.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
  }, [isAudienceArchetypeComplete, parsedData]);

  useEffect(() => {
    setIsWaitingTargetAudience(
      isTargetAudienceAudienceInsight.status &&
        isTargetAudienceBehavioralTrend.status &&
        isTargetAudienceMediaConsumptionPattern.status &&
        isTargetAudiencePerceptionAnalysis.status,
    );
  }, [
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
  ]);

  useEffect(() => {
    const handleGenerate = async () => {
      try {
        const { data } = await fetcher.get(
          `/${project.slug}/audience-archetype/check-objective`,
        );

        if (!isAudienceArchetypeComplete.status) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, audience archetype is still generating`,
            ),
          );
          return;
        }

        if (!isWaitingTargetAudience) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please wait, target audience is still generating`,
            ),
          );
          return;
        }

        if (data && !data.data.have_objective) {
          dispatch(
            saveSubInformation(
              `{text-error-redx} Please fill objective in submission first`,
            ),
          );
          return;
        }

        if (
          selectedIndex?.length === 1 &&
          differentArchetypes?.is_different_archetype.key_insight
        ) {
          setShowConfirmationModal(true);
        } else {
          handleRefetchSelectedData();
        }
      } catch (error) {
        console.error("Error in handleGenerate:", error);
      } finally {
        setIsRefetchDiffArch("idle");
      }
    };

    if (isRefetchDiffArch === "done") {
      (async () => {
        await handleGenerate();
      })();
    }
  }, [
    differentArchetypes,
    isPendingDiffArch,
    selectedIndex,
    isRefetchDiffArch,
  ]);

  useEffect(() => {
    dispatch(saveRouteNext(routeOptionValue));
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/target-audience`),
      }),
    );
    dispatch(saveInformation(""));
  }, []);

  useEffect(() => {
    if (selectedIndex !== undefined && selectedIndex.length) {
      const hasMany = selectedIndex.length > 1;
      dispatch(
        saveInformation(
          `${selectedIndex.length} audience ${
            hasMany ? "archetypes are" : "archetype is"
          } selected`,
        ),
      );
      dispatch(saveRouteNext({ ...routeOptionValue, isInactive: false }));
      return;
    }

    dispatch(saveInformation(`0 from ${parsedData.length} archetype selected`));
    dispatch(
      saveRouteNext({
        ...routeOptionValue,
        isInactive: true,
        isDisabled: false,
      }),
    );
  }, [selectedIndex]);

  useEffect(() => {
    if (parsedData?.length) {
      const selectedDataWithIndex = parsedData
        .map((item, index) => ({ item, index })) // Map to array of objects with item and index
        .filter(({ item }) => item.is_selected === 1);

      const indices = selectedDataWithIndex.map(({ index }) => index);
      setSelectedIndex(indices);
    }
  }, [parsedData]);

  const sectionList: SectionList = {
    title: "Audience Archetype",
    value: "archetype",
    section: "audience",
  };

  useEffect(() => {
    dispatch(setEnableHistoryStatus(!(isAdding || isEditing)));
  }, [isAdding, isEditing]);

  return (
    <Fragment>
      <Helmet>
        <title>Audience Archetype</title>
      </Helmet>
      {isGenerate ? (
        <GenerateLoading
          progress={progressPortion({
            progress: Number((state.audienceArchetype.progress / 2).toFixed(0)),
            isQuerying: isAudienceArchetypeComplete.isQuerying,
            isContentAvailable: parsedData?.length > 0,
          })}
          project={project}
          section={sectionList}
        />
      ) : (
        <div className='w-full h-full'>
          <div className='flex md:flex-col flex-row items-center justify-between mb-24 gap-16 sticky top-56 z-20 bg-white py-16'>
            <div className='flex flex-col grow'>
              <h1 className='text-25 font-bold mb-8 leading-none'>
                Audience Archetype
              </h1>
              <span className='text-1620 font-normal text-grey-redx'>
                Please{" "}
                <span className='font-bold text-black-redx'>
                  select one or more{" "}
                </span>{" "}
                audience archetypes, or click 'New Archetype' to add a new one
              </span>
            </div>
            {isEditor && (
              <div className='flex items-center'>
                <RegenerateButton
                  isGenerateMore
                  limit={totalRegenerate}
                  maxLimit={project.max_generated_data} // to do ganti ke max limit
                  onSubmit={handleRegenerateButton}
                />
              </div>
            )}
          </div>
          {!isLoading && !isAudienceArchetypeComplete.isQuerying ? (
            <SliderContainer
              isEditing={isEditing}
              isAdding={isAdding}
              length={parsedData.length}
              selectedIndexes={selectedIndex}
              goToIndex={goToIndex}
              onActiveIndex={(index) => setActiveIndex(index)}>
              {parsedData.map((archetype, index) => (
                <Card
                  totalSelected={selectedIndex?.length ?? 0}
                  project={project}
                  id={archetype.id}
                  totalArchetype={parsedData.length}
                  key={index}
                  isFetching={isRefetching}
                  archetype={archetype.archetype_content}
                  index={index}
                  isEditing={isEditing && isEditor}
                  isSelected={!!archetype.is_selected}
                  onSelectSuccess={onSelectSuccess}
                  selectedArchetype={selectedEditedData?.archetype_content}
                  activeIndex={activeIndex}
                  ref={(el) => (cardRefs.current[index] = el)}
                  onSubmitSuccess={handleFetchSubmit}
                  isEditor={isEditor}
                />
              ))}
              {isEditor && isAdding && (
                <Card
                  project={project}
                  id={-1}
                  totalSelected={selectedIndex?.length ?? 0}
                  totalArchetype={parsedData.length + 1}
                  archetype={{
                    archetype_name: "",
                    demographics: "",
                    occupation: "",
                    lifestyle: "",
                    behavior: "",
                    needs_and_challenges: "",
                    potential_buying_motives: "",
                    current_trends: "",
                    source_of_information: "",
                    online_search_behavior: "",
                    purchase_frequency: "",
                    preferred_sales_channels: "",
                  }}
                  activeIndex={activeIndex}
                  index={parsedData.length}
                  isEditing={isAdding}
                  onSelectSuccess={() => {}}
                  isSelected={false}
                  isEditor={isEditor}
                  selectedArchetype={selectedEditedData?.archetype_content}
                  ref={(el) => (cardRefs.current[parsedData.length] = el)}
                  onSubmitSuccess={handleFetchSubmit}
                />
              )}
            </SliderContainer>
          ) : (
            <Card
              project={project}
              id={-1}
              isEditor={isEditor}
              totalSelected={selectedIndex?.length ?? 0}
              totalArchetype={parsedData.length + 1}
              archetype={{
                archetype_name: "",
                demographics: "",
                occupation: "",
                lifestyle: "",
                behavior: "",
                needs_and_challenges: "",
                potential_buying_motives: "",
                current_trends: "",
                source_of_information: "",
                online_search_behavior: "",
                purchase_frequency: "",
                preferred_sales_channels: "",
              }}
              isFetching={true}
              activeIndex={activeIndex}
              index={-1}
              isEditing={isAdding}
              onSelectSuccess={() => {}}
              isSelected={false}
              selectedArchetype={undefined}
              ref={(el) => (cardRefs.current[parsedData.length] = el)}
              onSubmitSuccess={() => {}}
            />
          )}

          <div className='flex w-full justify-between items-center pt-12 mb-35'>
            {isEditor &&
              (!isAdding && !isEditing ? (
                <button
                  className='text-15 font-semibold inline-flex text-blue-redx gap-x-4'
                  onClick={toggleIsAdding}>
                  Add Archetype Manually
                  <Icon
                    icon='lucide:plus'
                    className='w-20 h-20'
                  />
                </button>
              ) : (
                <div></div>
              ))}
            <div className='flex justify-end items-center gap-x-15'>
              {isEditor &&
                (isEditing || isAdding ? (
                  <Fragment>
                    <button
                      className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                      onClick={isAdding ? toggleIsAdding : handleCancelEdit}>
                      <span className='text-15 font-semibold'>Cancel</span>
                      <Icon
                        icon='lucide:x'
                        className='w-20 h-20'
                      />
                    </button>
                    <button
                      disabled={isPending}
                      className={`inline-flex items-center gap-x-4 ${
                        isPending ? "text-gray-600" : "text-blue-redx"
                      } cursor-pointer`}
                      onClick={handleSubmit}>
                      <span className='text-15 font-semibold'>Save</span>
                      <Icon
                        icon='lucide:save'
                        className='w-20 h-20'
                      />
                    </button>
                  </Fragment>
                ) : (
                  <EditButton
                    toggleEditing={() => {
                      const currData = editedData[activeIndex];
                      if (currData) {
                        setSelectedEditedData(currData);
                        toggleIsEditing();
                      }
                    }}
                  />
                ))}
            </div>
          </div>
          <div className='flex w-full pb-60 mr-1'>
            {!isLoading && sources && <SourcesList sources={sources} />}
            {isLoading && (
              <div className='inline-flex gap-10'>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        handleGenerate={handleRefetchSelectedData}
      />

      <RegenerateInfoModal
        isOpen={showAlertGenerateModal}
        onClose={() => {
          setShowAlertGenerateModal(false);
        }}
      />

      <RegenerateModal
        isRegenerating={isRegenerating}
        isOpen={showRegenerateModal}
        handleSubmit={handleRegenerate}
        onClose={() => setShowRegenerateModal(false)}
      />
    </Fragment>
  );
};

export default AudienceArchetypeIndex;
