import React from "react";

import ModalCenter from "@/Components/Modals/Center";

const RegenerateModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}> = ({ isOpen, onClose, handleSubmit }) => {
  return (
    <ModalCenter
      isOpen={isOpen}
      onClose={onClose}>
      <div className={`relative w-full max-w-xl`}>
        <div className='flex transition-transform transform duration-500'>
          <div className='w-full flex-shrink-0 py-50 px-40 md:py-50 md:px-30'>
            <div className='mb-40'>
              <h1 className='font-semibold text-35 text-black-redx mb-16 text-center'>
                Regenerate Process Needed
              </h1>
              <p className='text-grey-redx text-15 text-center'>
                If you update the information, it will impact the output in
                other sections and a regenerate process will be needed.
              </p>
            </div>
            <div className='flex flex-col w-full gap-y-15'>
              <button
                className='w-full py-15 bg-blue-redx border-blue-redx border-2 text-white font-semibold rounded-10'
                onClick={handleSubmit}>
                Proceed
              </button>
              <button
                className='w-full py-15 bg-transparent border-blue-redx border-2 text-blue-redx font-semibold rounded-10'
                onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default RegenerateModal;
