import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

interface MenuLinkProps {
  to: string;
  icon?: string;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const MenuLink: React.FC<MenuLinkProps> = ({
  children,
  to,
  icon,
  disabled = false,
  isLoading = false,
  className = "",
}) => {
  const renderLinkContent = (isActive: boolean) => (
    <>
      {icon && !isLoading && (
        <Icon
          className={`w-24 h-24 mr-20 ${
            isActive ? "bg-white p-3 rounded-full text-blue-redx" : "font-light"
          }`}
          icon={icon}
          style={{
            transform:
              icon === "lucide:arrows-up-from-line" ? "rotate(90deg)" : "none",
          }}
        />
      )}
      {isLoading && (
        <Icon
          className='w-24 h-24 mr-20 animate-spin'
          icon='lucide:loader-circle'
        />
      )}
      {children}
    </>
  );

  return disabled ? (
    <div
      className={`w-full flex items-center text-16 pl-3 pr-4 py-2 !text-gray-400 !cursor-not-allowed ${className} transition duration-150 ease-in-out font-light`}>
      {renderLinkContent(false)}
    </div>
  ) : (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `w-full flex items-center text-16 pl-3 pr-4 py-2 ${
          isActive && !disabled
            ? "!text-white font-bold"
            : "!text-white font-light"
        } ${
          disabled ? "!text-gray-400 !cursor-not-allowed " : "cursor-pointer"
        } ${className} transition duration-150 ease-in-out`
      }
      end>
      {({ isActive }) => renderLinkContent(isActive)}
    </NavLink>
  );
};

export default MenuLink;
